@import url('https://fonts.googleapis.com/css?family=PT+Sans');
@import url('https://fonts.googleapis.com/css?family=Overpass:300,400,500,600');
@import url('https://fonts.googleapis.com/css?family=Inter:300,400,500,600');

/*****************************************************************************/
/* Global: Style */
/*****************************************************************************/
#tally-website {
  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
  }

  //body {
    font-family: 'Inter', 'Overpass', 'PT Sans', sans-serif;
    font-style: normal;
    font-weight: 300;
    //font-weight: 400;
    color: white;
  //}

  #render-target {
    position: relative;
  }

  /*****************************************************************************/
  /* Global: Style */
  /*****************************************************************************/
  /*****************************************************************************/
  /* Colors: Style */
  /*****************************************************************************/
  $color-cirq-dark: #5F25B2;
  $color-cirq-light: #964CFF;
  $color-cirq-white: #F3F0F7;
  $color-cirq-black: #130F19;
  $color-cirq-gray: #5D5766;
  $color-cirq-grey: #5D5766;
  $color-cirq-dark-purple: #220d40;
  $color-f7-gray: #8e8e93;
  $color-matte-purple: #7E47BF;
  $color-navbar-toolbar-white: #f7f7f8;
  $color-swiper: #D1CCDA;
  $color-matte-blue: #3893cc;
  $color-matte-red: #ff5f5f;
  $color-matte-green: #30b256;
  /*****************************************************************************/
  /* Colors: Style */
  /*****************************************************************************/
  /*****************************************************************************/
  /* Header Middle: Style */
  /*****************************************************************************/
  #header {
    width: 100%;
    background-color: white;
    position: relative;
    overflow: hidden;
    color: black;

    .download-photo {
      height: 51px;
    }

    .section {
      margin-left: 24%;
    }

    h2 {
      font-size: 40px;
      margin-bottom: 60px;
    }

    .sections {
      display: flex;
      align-items: center;
      min-height: 600px;
    }
  }

  @media screen and (max-width: 2000px) {
    #header .section {
      max-width: unset;
      margin-right: 10%;
      margin-left: 24%;
    }
  }
  @media screen and (max-width: 1500px) {
    #header .section {
      margin-left: 22%;
    }
  }
  @media screen and (max-width: 1150px) {
    #header .section {
      margin-left: 18%;
    }
  }
  @media screen and (max-width: 1000px) {
    #header .section {
      margin-right: 8%;
      margin-left: 15%;
    }
  }
  @media screen and (max-width: 940px) {
    #header .section {
      margin-left: 12%;
    }
  }
  @media screen and (max-width: 800px) {
    #header .sections {
      height: 100vh;
    }
  }
  @media screen and (max-width: 800px) {
    #header .section {
      margin: 15%;

      .header-text {
        float: unset;
        padding: 0;
        width: 100%;
        text-align: center;
      }
    }
  }

  @media screen and (max-width: 1240px) {
    #header .download-photo {
      height: unset;
    }
  }
  @media screen and (max-width: 800px) {
    #header .download-photo {
      margin: 0 auto 20px;
    }
  }

  .page {
    overflow: hidden;
  }

  i {
    font-style: italic;
  }

  // .column {
  //   display: flex;
  //   flex-direction: column;
  //   height: ~"calc(100% - 48px)"
  // }

  .header-text {
    float: left;
    display: block;
    width: 60%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    padding-left: 100px;
    padding-bottom: 75px;
  }

  .header-text:first-child {
    margin-left: 0;
  }

  @media screen and (max-width: 1300px) {
    .header-text {
      padding-left: 90px;
    }
  }
  @media screen and (max-width: 1250px) {
    .header-text {
      padding-bottom: unset;
    }
  }
  @media screen and (max-width: 1150px) {
    .header-text {
      padding-left: 80px;
    }
  }
  @media screen and (max-width: 1020px) {
    .header-text {
      padding-bottom: 50px;
    }
  }

  .phone {
    float: left;
    display: block;
    width: 50%;
    text-align: left;
  }

  .phone img {
    max-width: 340px;
    margin-left: 50%;
    transform: translateX(-50%);
  }

  @media screen and (max-width: 1200px) {
    .phone {
      bottom: 100px;
    }
  }
  @media screen and (max-width: 1024px) {
    .phone img {
      width: 100%;
    }
  }
  @media screen and (max-width: 938px) {
    .phone {
      display: none;
    }
  }

  .block {
    display: block;
  }

  .page-content {
    color: white;
    background-size: 1000px;
    background-repeat: repeat;
  }

  .intro {
    background-color: rgba(255, 255, 255, .97);
    overflow: hidden;
    color: black;
  }

  .middle {
    background-color: rgba(247, 247, 247, .97);
    color: black;
    color: #999999;
    color: white;

    .sections-container {
      padding: 0px 0;
    }

    .section-container:nth-child(even) {
      color: black;
    }

    h3 {
      margin: 0 0 25px;
    }
  }

  /*****************************************************************************/
  /* Header Middle: Style */
  /*****************************************************************************/
  /*****************************************************************************/
  /* Memes: Style */
  /*****************************************************************************/
  .meme-text-table {
    display: table;
    border-collapse: collapse;
    width: 100%;
  }

  .meme-text {
    font-weight: 400;
    display: table-cell;
    text-align: center;
    padding: 10px;
    font-size: 16px;
    vertical-align: middle;
  }

  @media screen and (max-width: 1400px) {
    .meme-text {
      line-height: 15px;
      font-size: 14px;
    }
  }
  @media screen and (max-width: 800px) {
    .meme-text {
      line-height: 15px;
      font-size: 13px;
    }
  }

  .meme-img {
    max-width: 450px;
    margin: 0 auto 5px;
    width: 100%;
    color: black;
    background: rgb(255, 255, 255);

    &:nth-child(2) {
      margin-bottom: 0;
    }
  }

  .meme-picture {
    max-width: 100%;
    display: block;
    min-height: 110px;
    margin: auto;
    vertical-align: bottom;
  }

  #kardashian-phone-image {
    width: 90%;
  }

  @media screen and (max-width: 1400px) {
    #kardashian-phone-image {
      width: 85%;
    }
  }

  .multi-meme-pack {
    img {
      width: 88%
    }
  }

  /*****************************************************************************/
  /* Memes: Style */
  /*****************************************************************************/
  /*****************************************************************************/
  /* Section: Style */
  /*****************************************************************************/
  .intro-container {
    position: relative;
    max-width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  .section-container {
    position: relative;

    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }

  .section {
    padding: 60px 0;
    display: flex;
    align-items: center;
    position: relative;
    max-width: 50%;
    margin-left: auto;
    margin-right: auto;
    min-height: 100%;
  }

  @media screen and (max-width: 2000px) {
    .section {
      max-width: 60%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  @media screen and (max-width: 1500px) {
    .section {
      max-width: 75%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  @media screen and (max-width: 1024px) {
    .section {
      max-width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  @media screen and (max-width: 800px) {
    .section {
      // padding-top: 0px;
      padding: 50px 0;
      display: block;
      align-items: unset;
    }
  }

  .section-text {
    width: 50%;
  }

  .section-imgs {
    text-align: center;
    width: 46%;

    img, .meme-picture {
      max-width: 100%;
      vertical-align: bottom;
    }
  }

  .section-imgs, .section-text {
    float: left;
    display: block;
    margin-right: 4%;

    &:last-child {
      margin-right: 0;
    }
  }

  @media screen and (max-width: 800px) {
    .section-imgs, .section-text {
      float: unset;
      margin: 40px 0;
      width: 100%;
      text-align: center;
    }
    .section-text {
      margin-bottom: 70px
    }
  }

  .sections-container {
    padding: 60px 0;
  }

  @media screen and (max-width: 700px) {
    .sections-container {
      padding-bottom: 0;
    }
  }
  /*****************************************************************************/
  /* Section: Style */
  /*****************************************************************************/
  /*****************************************************************************/
  /* General: Style */
  /*****************************************************************************/
  .box {
    height: 140px;
    background-image: url(http://www.hillspet.com/HillsPetUS/v1/portal/en/us/dog-care/images/HP_PCC_md_0144_dog37.jpg);
    background-size: cover;
    background-position: center center;
  }

  @media screen and (max-width: 1200px) {
    .box {
      height: 120px;
    }
  }

  //.page-content {
    h1 {
      font-size: 50px;
      line-height: 64px;
    }

    @media screen and (max-width: 1024px) {
      h1 {
        font-size: 44px;
        line-height: 57px;
      }
    }
    @media screen and (max-width: 768px) {
      h1 {
        font-size: 36px;
        line-height: 49px;
      }
    }
    @media screen and (max-width: 700px) {
      h1 {
        font-size: 30px;
        line-height: 43px;
      }
    }

    h2 {
      font-size: 48px;
      line-height: 55px;
      margin: 40px 0;
      letter-spacing: 1px;
    }

    @media screen and (max-width: 1024px) {
      h2 {
        font-size: 41px;
        line-height: 48px;
      }
    }
    @media screen and (max-width: 1024px) {
      h2 {
        font-size: 36px;
        line-height: 43px;
      }
    }
    @media screen and (max-width: 700px) {
      h2 {
        margin: 30px 0;
        font-size: 30px;
        line-height: 37px;
      }
    }

    h3 {
      font-size: 37px;
      line-height: 45px;
      margin-bottom: 25px;
    }

    @media screen and (max-width: 1280px) {
      h3 {
        font-size: 35px;
        line-height: 42px;
      }
    }
    @media screen and (max-width: 1024px) {
      h3 {
        font-size: 32px;
        line-height: 40px;
      }
    }
    @media screen and (max-width: 768px) {
      h3 {
        font-size: 26px;
        line-height: 33px;
      }
    }

    h1, h2, h3, h4, h5, h6 {
      font-weight: 500;
    }

    p {
      margin: 20px 0;
      font-size: 22px;
      //font-size: 24px;
      line-height: 1.4;
    }

    @media screen and (max-width: 1300px) {
      p {
        font-size: 20px;
        //font-size: 22px;
        // max-width: 425px;
        margin-left: auto;
        margin-right: auto;
      }
    }
    @media screen and (max-width: 768px) {
      p {
        font-size: 19px;
        max-width: 425px;
        margin-left: auto;
        margin-right: auto;
      }
    }
    @media screen and (max-width: 400px) {
      p {
        font-size: 18px;
      }
    }
  //}

  /*****************************************************************************/
  /* General: Style */
  /*****************************************************************************/
  /*****************************************************************************/
  /* Intro: Style */
  /*****************************************************************************/
  #intro {
    @media screen and (max-width: 1300px) {
      .section-text {
        padding: 0;
      }
    }
  }

  .intro-imgs.section-imgs {
    margin-left: 0;
    margin-right: 4%;
    text-align: center;
  }

  @media screen and (max-width: 700px) {
    .intro-imgs.section-imgs {
      margin-left: auto;
      margin-right: auto;
    }
  }
  /*****************************************************************************/
  /* Intro: Style */
  /*****************************************************************************/
  /*****************************************************************************/
  /* Footer: Style */
  /*****************************************************************************/
  .footer {
    background-color: #292929;
    padding: 75px 13%;

    .footer-logo {
      float: left;
      display: block;
      width: 150px;

      img {
        width: 100px;
      }

      &:last-child {
        width: 15%;
      }
    }

    .links {
      float: left;
      display: block;
      width: 60%;

      a {
        color: white;
        font-size: 19px;
        text-decoration: none;
        padding-right: 25px;
        line-height: 42px;
      }

      .first-column, .second-column {
        display: inline;
      }

      &:last-child {
        width: 65%;
      }
    }

    .social {
      float: right;
      display: block;
      text-align: right;

      &:last-child {
        margin-right: 0;
      }

      img {
        margin: 0 0 8px 8px;
      }
    }
  }

  @media screen and (max-width: 1280px) {
    .footer {
      padding: 75px 8%;
    }
  }
  @media screen and (max-width: 1024px) {
    .footer .social {
      width: 110px;
    }
  }
  @media screen and (max-width: 768px) {
    .footer {
      padding: 75px 70px;

      .footer .links {
        float: left;
        display: block;
        width: 55%;

        a {
          font-size: 17px;
          line-height: 35px;
        }

        .first-column, .second-column {
          float: left;
          display: block;
          margin-right: 5%;
          width: 50%;

          a {
            display: block;
          }

          &:last-child {
            margin-right: 0;
          }
        }

        &:last-child {
          width: 55%;
        }
      }

      .footer-logo {
        margin-right: 2.5%;
        width: 100%;
        margin-bottom: 10px;

        img {
          width: 85px;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  @media screen and (max-width: 700px) {
    .footer {
      padding: 30px 5%;
    }
  }
  @media screen and (max-width: 400px) {
    .footer {
      padding: 30px 20px;
    }
  }

  .footer-container {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }

  /*****************************************************************************/
  /* Footer: Style */
  /*****************************************************************************/
  #download-logo {
    width: 185px;
  }

  .download-photo {
    height: 60px;

    &:first-child {
      margin-right: 20px;
    }
  }

  @media screen and (max-width: 1240px) {
    .download-photo {
      height: unset;
      width: 202px;
      display: block;

      &:first-child {
        margin-bottom: 15px;
      }
    }
  }
  @media screen and (max-width: 580px) {
    .download-photo {
      width: 175px;
    }
  }

  .facebook {
    color: #3B5998;
  }

  .google {
    display: inline-block;

    .red {
      color: #EA4335;
    }

    .green {
      color: #34a853;
    }

    .blue {
      color: #4285f4;
    }

    .yellow {
      color: #fbbc05;
    }
  }

  .color-tally {
    color: $color-matte-purple;
  }

  .identity {
    margin: 0 auto;
  }

  .cell-right {
    text-align: right;
    vertical-align: top;
  }

  .identity-table {
    border-collapse: collapse;
    display: table;
  }

  // @media screen and (max-width: 800px) {
  //   .identity-table {margin-top: 80px;}}

  .identity-paragraphs {
    text-align: center;
    display: none;
  }

  @media screen and (max-width: 700px) {
    .identity-table {
      display: none;
    }
    .identity-paragraphs {
      display: block;
    }
  }

  #bottom {
    font-weight: 400;
    background-color: rgb(255, 255, 255);
    color: black;

    .section {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  @media screen and (max-width: 800px) {
    #bottom .sections-container {
      height: 100vh;
    }
  }
  @media screen and (max-width: 700px) {
    #bottom .sections-container {
      padding: 50px 0;
    }
  }

  #bottom td, #bottom p {
    font-size: 40px;
    line-height: 50px;
  }

  @media screen and (max-width: 1280px) {
    #bottom td, #bottom p {
      font-size: 36px;
      line-height: 46px;
    }
  }
  @media screen and (max-width: 1024px) {
    #bottom td, #bottom p {
      font-size: 32px;
      line-height: 42px;
    }
  }
  @media screen and (max-width: 960px) {
    #bottom td, #bottom p {
      font-size: 30px;
      line-height: 40px;
    }
  }
  // @media screen and (max-width: 790px)  {
  //   #bottom td, #bottom p {
  //     font-size: 26px;
  //     line-height: 33px;}}
  @media screen and (max-width: 700px) {
    #bottom p {
      margin: 15px auto;
      font-size: 26px;
      line-height: 34px;
    }
  }
  @media screen and (max-width: 550px) {
    #bottom p {
      font-size: 21px;
      line-height: 27px;
    }
  }
  @media screen and (max-width: 320px) {
    #bottom p {
      font-size: 19px;
      line-height: 24px;
    }
  }
  /*****************************************************************************/
  /* Icons: Style */
  /*****************************************************************************/
  /*****************************************************************************/
  /* Icons: Style */
  /*****************************************************************************/
  /*****************************************************************************/
  /* Text Block: Style */
  /*****************************************************************************/
  .text-block {
    padding-left: 15px;
    padding-right: 15px;
    max-width: 1000px;
  }

  /*****************************************************************************/
  /* Text Block: Style */
  /*****************************************************************************/
  .button-background {
    display: flex;
    justify-content: center;
    border-radius: 10px;
    height: 59px;
    background-size: 200% auto;
    transition: background-position 0.5s linear;
    background-image: linear-gradient(to right, #7e47bf 0%, #524CBF 51%, #2B34BF 100%);

    &:hover {
      background-position: right center;
    }
  }

  #download-stripe {
    margin-top: 100px;
    display: flex;
    width: 450px;
    justify-content: space-around;
    background-color: white;
    // background-image: linear-gradient(120deg, #336cd6 0%, #58aee8 100%);
    .download-photo {
      &:first-child {
        margin-right: unset;
      }
    }

    @media screen and (max-width: 1240px) {
      .download-photo {
        width: unset;
        height: 61px;
      }
      .button-background {
        height: 60px;
      }
    }
    @media screen and (max-width: 960px) {
      .download-photo {
        height: 45px;
      }
      .button-background {
        height: 46px;
      }
    }
  }

  @media screen and (max-width: 960px) {
    #download-stripe {
      width: 380px;
    }
  }
  @media screen and (max-width: 800px) {
    #download-stripe {
      margin: 100px auto 0;
    }
  }
  @media screen and (max-width: 430px) {
    #download-stripe {
      width: 330px;
    }
  }
}

$color-f7-gray: #8e8e93;
$color-navbar-toolbar-white: #f7f7f8;
$color-matte-purple: #7E47BF;
$color-cirq-black: #130F19;

#tally-website {
  .center-text {
    text-align: center;
  }

  .route-page {
    background: lighten(#E8E4F1, 5%);
    font-family: "proxima-nova", "Helvetica Neue", arial, sans-serif;

    h1, h2, h3 {
      color: #333;
    }

    p {
      color: #777;
    }

    .heading {
      color: $color-matte-purple;
    }

    .subheading, .small-subheading {
      text-align: center;
      color: #333;
    }
  }

  .chapter {
    font-family: "Roboto", Helvetica, Arial, Verdana, sans-serif;
    color: #212121;
  }

  a:not(.btn) {
    color: $color-matte-purple;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &:visited {
      color: $color-matte-purple;
    }
  }

  hr {
    margin: 0;
  }

  @media screen and (max-width: 620px) {
    .mobile-center {
      text-align: center;
    }
  }

  .card {
    background: #fff;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    color: #333;
    overflow: hidden;
    margin: 60px auto;
    max-width: 60%;
    min-height: 650px;
    box-shadow: 2px 2px 50px 0px rgba(50, 50, 50, 0.1);
  }

  @media screen and (max-width: 2000px) {
    .card {
      max-width: 65%
    }
  }
  @media screen and (max-width: 1500px) {
    .card {
      max-width: 75%
    }
  }
  @media screen and (max-width: 1024px) {
    .card {
      max-width: 80%
    }
  }
  @media screen and (max-width: 620px) {
    .card {
      max-width: none;
      margin: 0;
    }
  }

  .tab-content {
    h1 {
      display: inline-block;
      font-weight: 600;
      font-size: 28px;
      max-width: 400px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-bottom: 15px;
    }

    h2 {
      font-size: 22px;
      font-weight: 600;
      letter-spacing: 0px;
      margin: 25px 0 10px;
    }

    h3 {
      font-size: 20px;
      margin: 20px 0 10px;
    }

    ul {
      list-style: none;
      margin-bottom: 20px;
    }

    li {
      list-style: none;
    }

    .card-p {
      line-height: 1.5;
      font-size: 19px;
    }

    p {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 20px;
      @media screen and (max-width: 1100px) {
        p {
          font-size: 18px;
          line-height: 24px;
        }
      }

      &.heading {
        text-align: center;
        font-size: 42px;
        line-height: 50px;
        margin-bottom: 30px;
      }

      &.subheading {
        font-size: 30px;
        line-height: 36px;
        margin-top: 40px;
      }

      &.small-subheading {
        font-size: 20px;
        line-height: 26px;
        margin-top: 40px;
        margin-bottom: 10px;
      }
    }
  }

  .tab-content {
    max-width: 760px;
    margin: 30px auto 100px;
  }

  @media screen and (max-width: 1100px) {
    .tab-content {
      margin: 25px 110px 100px;
    }
  }
  @media screen and (max-width: 800px) {
    .tab-content {
      margin: 20px 70px 100px;
    }
  }
  @media screen and (max-width: 480px) {
    .tab-content {
      margin: 20px 40px 100px;
    }
  }

  .card-section {
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    max-width: 85%;
  }

  @media screen and (max-width: 620px) {
    .card-section {
      max-width: none;
    }
  }

  .navbar {
    background-color: $color-matte-purple;
    height: 80px;
    width: 100%;
    z-index: 200;
    position: relative;
    background-size: 1000px;
  }

  .navbar-logo {
    background-size: 80px 40px;
    display: inline-block;
    width: 80px;
    height: 40px;
    text-decoration: none;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .tab.active {
    color: $color-cirq-black;
  }

  .tab:hover {
    color: $color-cirq-black;
  }

  .tab {
    text-transform: capitalize;
    display: inline-block;
    color: $color-f7-gray;
    height: 70px;
    line-height: 90px;
    font-size: 21px;
    width: 25%;
    text-align: center;
  }

  @media screen and (max-width: 480px) {
    .tab {
      height: 70px;
      line-height: 72px;
      font-size: 19px;
    }
  }

  .flex-line {
    display: flex;
  }

  .flex-left {
    margin-right: auto;
  }

  .flex-right {
    padding: 20px 0;
    margin-left: auto;
  }

  .chapter {
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    cursor: pointer;
    margin: 30px -30px;

    p {
      color: #212121;
    }

    &.active .accordion-content {
      max-height: 50000px;
    }
  }

  @media screen and (min-width: 1100px) and (max-width: 1240px) {
    .chapter {
      margin: 30px 0px;
    }
  }
  @media screen and (max-width: 480px) {
    .chapter {
      margin: 30px -15px;
    }
    .chapter p {
      font-size: 17px;
    }
    .chapter-header {
      padding: 20px 15px;
    }
    .chapter-content {
      padding: 20px 15px;
    }
  }

  .transition {
    //-webkit-transition: all .4s linear;
    //-moz-transition: all .4s linear;
    //-ms-transition: all .4s linear;
    //-o-transition: all .4s linear;
    transition: all .4s linear;
  }

  .chapter-header {
    color: #212121;
    padding: 20px 30px;
    transition: all .4s linear;
    background: lighten(#E8E4F1, 5%);
    border-bottom: 1px solid lighten(#E8E4F1, 5%);

    &.active {
      // border-bottom: 1px solid #ffffff;
      background: #EAEAFF;
      border-bottom: 1px solid #EAEAFF;
      // background: #E8E4F1;
      .chapter-link, .chapter-date {
        color: #000000;
      }
    }
  }

  .chapter-title {
    // font-weight: 600;
    font-size: 21px;
    line-height: 38px;
  }

  .chapter-date {
    transition: all .4s linear;
    color: #9e9e9e;
    line-height: 20px;
    font-size: 17px;
  }

  .chapter-link {
    transition: all .4s linear;
    color: #9e9e9e;
    font-size: 17px;
  }

  @media screen and (max-width: 600px) {
    .chapter-link {
      display: none;
    }
  }

  .chapter-footer {
    transition: all .4s linear;
    color: #9e9e9e;
    position: absolute;
    bottom: 14px;
    right: 25px;
    text-align: right;
    display: none;
  }

  @media screen and (max-width: 600px) {
    .chapter-footer {
      display: unset;
    }
  }

  .chapter-content {
    position: relative;
    padding: 20px 30px 42px;
  }

  @media screen and (max-width: 480px) {
    .chapter-footer {
      display: 20px 25px 40px;
    }
  }

  .accordion-content {
    -webkit-transition: max-height 1s;
    -moz-transition: max-height 1s;
    -ms-transition: max-height 1s;
    -o-transition: max-height 1s;
    transition: max-height 1s;
    overflow: hidden;
    max-height: 200px;
  }
}

// background: #EAEAFF;
//   border-bottom: 1px solid #EAEAFF;
//   &.active {
//     border-bottom: 1px solid #ffffff;
//     background: darken(#EAEAFF, 5%);
//     border-bottom: 1px solid darken(#EAEAFF, 5%);

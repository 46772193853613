@import url(https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700);

#draftkings-app {
  .home-content {
    width: 100%;
    height: 100%;
  }

  .background-color {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    &.background-0 {
      background: linear-gradient(to right, #fd7643, #b03149);
      // animation: fadeInOut 25s ease;
      // animation-fill-mode: forwards;
      // animation-iteration-count: infinite;
    }

    &.background-1 {
      background: linear-gradient(to right, #b03149, #fd7643);
      // animation: fadeOutIn 25s ease;
      // animation-fill-mode: forwards;
      // animation-iteration-count: infinite;
    }

    &.background-2 {
      background: #f63e34;
      z-index: -2;
    }
  }


  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes fadeOutIn {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .parallax {
    perspective: 100px;
    height: 106vh;
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
    top: 0;
    left: 50%;
    right: 0;
    bottom: 0;
    margin-left: -1500px;
  }

  .parallax__layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    > img {
      display: block;
      position: absolute;
      bottom: 0;
      max-width: 3000px;
      max-height: 1563px;
    }
  }

  .parallax__layer__0 {
    top: 420px;
    bottom: 350px;
  }

  .parallax__layer__1 {
    top: -100px;
    bottom: 400px;
  }

  .parallax__layer__2 {
    top: -400px;
    bottom: 600px;
  }

  #parallax__cover {
    background-color: linear-gradient(to bottom, #294654, #000000);
    background-size: 3000px 200px;
    position: absolute;
    top: 100%;
    left: 832px;
    width: 100vw;
    min-height: 300px;
    z-index: 5;
  }

  $parallax__layers: 8;
.parallax__layer__0 {
  transform: translateZ(-480px) scale(5);
}
.parallax__layer__1 {
  transform: translateZ(-420px) scale(4.5);
}
.parallax__layer__2 {
  transform: translateZ(-360px) scale(4);
}
.parallax__layer__3 {
  transform: translateZ(-300px) scale(3.5);
}
.parallax__layer__4 {
  transform: translateZ(-240px) scale(3);
}
.parallax__layer__5 {
  transform: translateZ(-180px) scale(2.5);
}
.parallax__layer__6 {
  transform: translateZ(-120px) scale(2);
}
.parallax__layer__7 {
  transform: translateZ(-60px) scale(1.5);
}
.parallax__layer__8 {
  transform: translateZ(0px) scale(1);
}

  //@for $i from 0 through $parallax__layers {
  //  $x:($parallax__layers - $i)/2;
  //  .parallax__layer__#{$i} {
  //    transform: translateZ(-120 * $x#{px}) scale($x + 1);
  //  }
  //}

  #koth {
    width: 250px;
    height: 325px;
  }

  #become {
    height: 130px;
    margin: 20px auto;
  }

  #fantasy {
    height: 60px;
    margin: 20px auto;
  }

  #kk-holder {
    position: relative;
    width: 100%;
    max-width: 1024px;
    margin: auto;

    img {
      display: block;
    }
  }

  .media-row {
    padding: 0 50px 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  iframe {
    border: none;
  }

  #enter-button {
    color: white;
    display: block;
    text-transform: uppercase;
    text-decoration: none;
    font-family: 'Open Sans Condensed', sans-serif;
    font-weight: 700;
    text-align: center;
    letter-spacing: 0.25px;
    cursor: pointer;
    width: 270px;
    margin: 0 auto 60px;
    border-bottom: 3px solid white;
    background: #f2493a;
    height: 46px;
    font-size: 17px;
    line-height: 45px;
    border-radius: 5px;
  }
}

#tally-website {

  .absolute-picture {
    position: absolute;
    display: block;
    vertical-align: middle;
    bottom: 0;
  }

  @media (min-width: 801px) {
    .front-bg {
      display: inline;
    }
    .mobile-intro-img {
      display: none;
    }
    .full-window-height {
      height: 100vh;
    }
  }

  .parallax {
    overflow: hidden;
  }

  .parallax-layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  /*****************************************************************************/
  /* Bar: Style */
  /*****************************************************************************/
  #bar-beer {
    top: -60px;
    right: -60px;
    width: 270px;
  }

  #bar-burger {
    top: 34%;
    left: -640px;
    width: 580px;
    transform: scale(-1, 1) rotate(-90deg) translateY(-50%);
  }

  #bar-sandwich {
    top: 4%;
    left: -960px;
    width: 768px;
    transform: scale(-1, -1) rotate(-77deg) translateY(-50%);
  }

  #bar-cash {
    right: -214px;
    bottom: -222px;
    height: 420px;
    transform: rotate(-26deg) translateX(-6%);
  }

  @media screen and (max-width: 1300px) {
    #bar-beer {
      top: -20px;
      width: 200px;
    }
    #bar-burger {
      left: -460px;
      width: 445px;
    }
    #bar-sandwich {
    }
    #bar-cash {
      bottom: -150px;
      height: 330px;
    }
  }
  @media screen and (max-width: 800px) {
    #bar-sandwich {
    }
    #bar-burger {
      left: -540px;
    }
    #bar-cash {
      bottom: -220px;
    }
  }
  /*****************************************************************************/
  /* Bar: Style */
  /*****************************************************************************/
  /*****************************************************************************/
  /* Cafe: Style */
  /*****************************************************************************/
  #cafe-coffee {
    top: -155px;
    left: calc(50% - 170px);
    width: 340px;
  }

  #cafe-planner {
    width: 920px;
    top: 50%;
    transform: translate(77%, -20%) rotateZ(18deg);
    right: -50px;
  }

  #cafe-wrap {
    bottom: -35%;
    left: -320px;
    width: 620px;
  }

  @media screen and (max-width: 1300px) {
    #cafe-coffee {
      top: -70px;
      width: 255px;
    }
    #cafe-planner {
      width: 690px;
    }
    #cafe-wrap {
      bottom: -22%;
      left: -350px;
      width: 470px;
    }
  }
  @media screen and (max-width: 1000px) {
    #cafe-planner {
      right: -120px;
    }
  }
  /*****************************************************************************/
  /* Cafe: Style */
  /*****************************************************************************/
  /*****************************************************************************/
  /* Park: Style */
  /*****************************************************************************/
  #park-flops {
    width: 800px;
    transform: scaleX(-1) rotateZ(61deg);
    top: 20%;
    right: -584px;
  }

  #park-speaker {
    width: 440px;
    transform: rotateZ(-18deg);
    top: -115px;
    left: -105px;
  }

  #park-sunglasses {
    bottom: -8%;
    width: 429px;
    left: -11%;
    transform: rotateZ(19deg);
  }

  // #park-frisbee {
  //   bottom: -28%;
  //   width: 470px;
  //   left: -13%;
  //   transform: rotateZ(26deg) translateY(3%);}
  @media screen and (max-width: 1300px) {
    #park-flops {
      right: -365px;
      width: 515px;
    }
    #park-speaker {
      top: -45px;
      width: 335px;
    }
    // #park-frisbee {width: 380px;}
  }
  @media screen and (max-width: 1300px) {
    #park-flops {
      right: -400px;
    }
  }
  /*****************************************************************************/
  /* Park: Style */
  /*****************************************************************************/
  /*****************************************************************************/
  /* Movies: Style */
  /*****************************************************************************/
  #movies-soda {
    top: -34px;
    right: -108px;
    transform: rotateZ(-8deg);
    width: 265px;
  }

  #movies-SPK {
    width: 520px;
    bottom: 0;
    right: -180px;
    transform: rotateZ(22deg) translate(30%, 65%);
  }

  #movies-tickets {
    left: -141px;
    transform: rotateZ(-25deg) translateY(-50%);
    width: 345px;
    top: 54%;
  }

  @media screen and (max-width: 1300px) {
    #movies-soda {
      width: 200px;
      top: -9px;
      right: -67px;
    }
    #movies-SPK {
      right: -140px;
      width: 390px;
    }
    #movies-tickets {
      width: 300px;
    }
  }
  @media screen and (max-width: 800px) {
    #movies-tickets {
      left: -200px;
    }
    #movies-SPK {
      right: -200px;
    }
  }

  @media screen and (max-width: 800px) {
    #movies-soda, #park-speaker, #bar-beer, #cafe-coffee {
      top: -120px;
    }
  }
  /*****************************************************************************/
  /* Movies: Style */
  /*****************************************************************************/
  /*****************************************************************************/
  /* Columns: Style */
  /*****************************************************************************/
  #sticky-phone {
    width: 324px;
  }

  // @media screen and (max-width: 1300px){
  //   #sticky-phone {width: 324px;}}
  @media screen and (max-width: 1400px) {
    #sticky-phone {
      width: 275px;
    }
  }
  // @media screen and (max-width: 900px){
  //   #sticky-phone {width: 275px;}}

  .sticky-phone-fixed {
    // pointer-events: none;
    position: fixed;
    height: 100vh;
    max-width: inherit;
    top: 0;
    z-index: 17;
  }

  .sticky-phone-unfixed {
    position: relative;
    width: 400px;
    margin-left: 75px;
    height: calc(100vh - 120px);
  }

  // @media screen and (max-width: 1300px){
  //   .sticky-phone-unfixed {margin-left: 75px;}}
  // @media screen and (max-width: 1500px){
  //   .sticky-phone-unfixed {margin-left: 140px;}}
  // @media screen and (max-width: 1400px){
  //   .sticky-phone-unfixed {margin-left: 125px;}}
  // @media screen and (max-width: 900px){
  //   .sticky-phone-unfixed {margin-left: 16px;}}

  @media screen and (max-width: 2000px) {
    .sticky-phone-unfixed {
      margin-left: 14%;
    }
  }
  @media screen and (max-width: 1500px) {
    .sticky-phone-unfixed {
      margin-left: 27%;
    }
  }
  @media screen and (max-width: 1150px) {
    .sticky-phone-unfixed {
      margin-left: 16%;
    }
  }
  @media screen and (max-width: 1000px) {
    .sticky-phone-unfixed {
      margin-left: 16%;
    }
  }
  // @media screen and (max-width: 940px) {
  //   .sticky-phone-unfixed {margin-left: 12%;}}
  @media screen and (max-width: 900px) {
    .sticky-phone-unfixed {
      margin: 5.5%;
    }
  }


  .mobile-width.sticky-phone-unfixed {
    margin: auto;
    height: 600px;
  }

  //width block
  @media screen and (max-width: 800px) {
    .sticky-phone-fixed {
      display: none;
    }
  }
  @media screen and (min-width: 801px) {
    .mobile-width.sticky-phone-unfixed {
      display: none;
    }
  }
  /*****************************************************************************/
  /* Columns: Style */
  /*****************************************************************************/
  /*****************************************************************************/
  /* Card: Style */
  /*****************************************************************************/
  .front-bg-card {
    top: 50%;
    // -webkit-transform: translate3d(0, -50%, 0);
    transform: translateY(-50%);
    left: -70px;
  }

  @media (min-height: 520px) {
    .front-bg-card {
      width: 80%;
    }
  }
  @media (min-height: 670px) {
    .front-bg-card {
      width: 100%;
    }
  }

  #white-back-phone {
    position: absolute;
    top: calc(50% + 4px);
    width: 365px;
    width: 331px;
  }

  // @media screen and (max-width: 1300px){
  //   #white-back-phone {width: 328px;}}
  @media screen and (max-width: 1400px) {
    #white-back-phone {
      width: 278px;
    }
  }
  @media screen and (max-width: 900px) {
    #white-back-phone {
      width: 278px;
    }
  }
  /*****************************************************************************/
  /* Card: Style */
  /*****************************************************************************/
  /*****************************************************************************/
  /* Something: Style */
  /*****************************************************************************/
  #phone-column {
    position: relative;
    float: left;
    clear: none;
    text-align: inherit;
    width: 400px;
    width: 300px;
    margin-right: 30px;

    &:after, &:before {
      content: '';
      display: table;
    }

    &:after {
      clear: both;
    }

    &:last-child {
      margin-bottom: 0;
      margin-right: 0;
    }
  }

  @media screen and (max-width: 1400px) {
    #phone-column {
      width: 260px;
    }
  }
  // @media screen and (max-width: 900px){
  //   .content-col {
  //     width: 250px;}}

  #dots {
    position: absolute;
    //right: -5px;
    right: -30px;
    top: 45%;
    width: 20px;
  }

  @media screen and (max-width: 1400px) {
    #dots {
      right: -30px;
    }
  }
  @media screen and (max-width: 900px) {
    #dots {
      right: -22px;
    }
  }
  //width block
  @media screen and (max-width: 800px) {
    #dots {
      display: none;
    }
  }

  .dot {
    background-color: #fff;
    //border: 4px solid #7E47BF;
    border: 4px solid #544e5b;
    border-radius: 50%;
    display: inline-block;
    margin-right: 8px;
    transform: scale(.3);
    height: 25px;
    width: 25px;
    z-index: 100;
    transition: transform 0.6s ease-in-out;

    &.active {
      transform: scale(.7);
    }

    &.dot-transition-enter {
      transform: scale(0);

      &.dot-transition-enter-active {
        transform: scale(.3);
        transition: transform 450ms ease-in-out;
      }
    }

    &.dot-transition-exit {
      transform: scale(.3);

      &.dot-transition-exit-active {
        transform: scale(0);
        transition: transform 450ms ease-in-out;
      }
    }
  }

  #phone-images {
    position: relative;
    height: 100%;
  }

  .front-bg-phone {
    position: absolute;
    max-width: 100%;
    width: 360px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  #phone-screen-container {
    border-radius: 4px;
    border: 2px solid #232332;
    top: calc(50% - 1px);
    left: calc(50% + 1px);
    //width: 314px;
    //height: 557px;
    width: 282px;
    height: 503px;
    position: relative;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    transform: translate(-50%, -50%);
    overflow: hidden;
  }

  // @media screen and (max-width: 1300px){
  //   #phone-screen-container {
  //     width: 282px;
  //     height: 503px;}}
  // @media screen and (max-width: 100px){
  //   #phone-screen-container {
  //     width: 266px;
  //     height: 474px;}}
  @media screen and (max-width: 1400px) {
    #phone-screen-container {
      width: 245px;
      height: 425px;
    }
  }

  .phone-screen-memes {
    padding: 10px;
  }

  #phone-home-screen {
    height: 100%;
  }

  @media screen and (max-width: 1400px) {
    #phone-home-screen {
      width: 100%;
    }
  }

  //.cross-fade-enter {
  //  opacity: 0;
  //  position: absolute;
  //  left: 100%;
  //  &.cross-fade-enter-active {
  //    opacity: 1;
  //    left: 0;
  //    transition: opacity 0.6s ease-out, left 0.6s ease-out;}}
  //.cross-fade-leave {
  //  opacity: 1;
  //  position: absolute;
  //  left: 0;
  //  &.cross-fade-leave-active {
  //    opacity: 0;
  //    left: -100%;
  //    transition: opacity 0.6s ease-out, left 0.6s ease-out;}}

  .screen-transition-height {
    position: static !important;
  }

  .screen-transition {
    //position: absolute;
  }

  .phone-screen {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    background: white;

    &.screen-transition-enter {
      //position: absolute;
      top: 100%;

      &.screen-transition-enter-active {
        top: 0;
        transition: top 450ms ease-in-out;
      }
    }

    &.screen-transition-leave {
      //position: absolute;
      top: 0;

      &.screen-transition-leave-active {
        top: -100%;
        transition: top 450ms ease-in-out;
      }
    }
  }

  .phone-screen.phone-screen-down {
    &.screen-transition-enter {
      top: -100%;

      &.screen-transition-enter-active {
        top: 0;
        transition: top 450ms ease-in-out;
      }
    }

    &.screen-transition-leave {
      top: 0;

      &.screen-transition-leave-active {
        top: 100%;
        transition: top 450ms ease-in-out;
      }
    }
  }

  //.screen-transition-enter {
  //  background-color: red;
  //  &.screen-transition-enter-active {
  //    background-color: green;
  //    transition: background-color 3000ms ease-in-out;
  //  }}
  //.screen-transition-exit {
  //  background-color: green;
  //  &.screen-transition-exit-active {
  //    background-color: red;
  //    transition: background-color 3000ms ease-in-out;
  //  }}
  //.screen-transition-leave {
  //  background-color: green;
  //  &.screen-transition-leave-active {
  //    background-color: red;
  //    transition: background-color 3000ms ease-in-out;
  //  }}


  /*****************************************************************************/
  /* Something: Style */
  /*****************************************************************************/
  /*****************************************************************************/
  /* Twitter: Style */
  /*****************************************************************************/
  .permalink-header {
    align-items: center;
    // display: flex;
    height: 48px;
    color: #2FC2EF;
    // margin-bottom: 10px;
    a {
      color: #1da1f2 !important;
    }
  }

  .account-group {
    display: flex;
    float: left;
  }

  .fullNameGroup {
    display: block;
    text-align: left;
    color: #14171a;
    font-size: 16px;
    font-weight: 600;
    line-height: 25px;
  }

  .username {
    font-size: 14px;
    color: #657786;
  }

  .account-text {
    display: flex;
    flex-direction: column;
    margin-left: 7px;
  }

  .avatar-img {
    width: 44px;
    height: 44px;
    border-radius: 5px;
  }

  .follow-button {
    float: right;
    margin-top: 4px;
    font-size: 19px;
    line-height: 20px;
    padding: 6px 16px;
    background-color: #fff;
    border: 1px solid #1da1f2;
    border-radius: 4px;
    font-weight: bold;
    text-align: center;
  }

  @media screen and (max-width: 1400px) {
    .permalink-header {
      height: 36px;
    }
    .fullNameGroup {
      font-size: 15px;
      line-height: 22px;
    }
    .username {
      font-size: 13px;
    }
    .avatar-img {
      width: 36px;
      height: 36px;
      border-radius: 4px;
    }
    .follow-button {
      margin-top: 2px;
      font-size: 17px;
      line-height: 20px;
      padding: 5px 16px;
      border-radius: 4px;
    }
  }

  // .follow-icon {
  //   vertical-align: middle;
  //   padding-left: 3px;
  //   font-size: 18px;
  //   position: relative;
  //   top: -1px;
  //   padding-right: 2px;}
  /*****************************************************************************/
  /* Twitter: Style */
  /*****************************************************************************/
}

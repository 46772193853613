/*****************************************************************************/
/* General: Style */
/*****************************************************************************/
* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(255,255,255,0);}

body {
  background-attachment: fixed;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
  font-size: 14px;
  .arrow_1d5fttb-o_O-arrow__direction__left_shhpn5-o_O-arrow__size__medium_9f7hgo {left: 0;}
  .arrow_1d5fttb-o_O-arrow__direction__right_174p6a9-o_O-arrow__size__medium_9f7hgo {right: 0;}}

//@keyframes glow {
//  0% {
//    filter: hue-rotate(0deg);
//  }
//  100% {
//    filter: hue-rotate(360deg);
//  }
//}

#tambamon {
  text-transform: lowercase;
  font-family: Helveticca, sans-serif;

  //#new-banner {
  //  font-size: 24px;
  //  font-weight: 600;
  //  color: white;
  //  display: flex;
  //  background-color: black;
  //  padding: 8px 16px;
  //  border-radius: 20px;
  //  align-self: center;
  //  margin-left: 12px;
  //  margin-top: -4px;
  //  max-width: 80px;
  //}

  #link-instructions {
    display: flex;
    align-items: center;}
  @media screen and (max-width: 620px) {
    #link-instructions h2 {
      margin-top: 0;
      margin-bottom: 0;}}

  //.letter {
  //  margin: -2px 0px 0 0px;
  //  color: white;
  //  color: yellow;
  //  text-shadow: 0 0 15px yellow, 0 0 25px yellow;
  //  animation: glow 6s linear infinite;
  //}

  #open-icon {
    font-size: 30px;
    margin-left: 8px;}

  h1 {
    font-size: 30px;
    margin: 23px 0;
    font-weight: 600;}
  h2 {
    font-size: 22px;
    margin: 19px 0;
    font-weight: 600;}
  h3 {
    font-size: 17px;
    margin: 12px 0;
    font-weight: 600;}
  h4 {
    font-size: 14px;
    margin: 8px 0;
    font-weight: 600;}
  p {
    font-size: 14.5px;
    margin: 14px 0;}
  a {
    color: unset;
    text-decoration: unset;}
  table {width: 100%;}
  tbody, tr {width: 100%;}

  .bold-500, .bold-500 h2, .bold-500 h1 {font-weight: 500;}
  /*****************************************************************************/
  /* General: Style */
  /*****************************************************************************/
  /*****************************************************************************/
  /* Format: Style */
  /*****************************************************************************/
  .container {
    width: 100vw;
    height: 100vh;}

  .resume {
    color: black;
    float: right;
    height: 100%;
    position: relative;
    width: 73%;}

  .resume-content {
    margin: 0 auto;
    max-width: 975px;
    padding: 50px 50px 200px;
    overflow: hidden;
    p {
      font-size: 20px;
      font-weight: 400;
      line-height: 1.4;
      color: rgba(0,0,0,.4);}}
  @media screen and (max-width: 620px) {
    .resume-content {padding: 50px 5px 100px;}}

  .resume-section {
    margin: 105px auto;
    display: flex;
    flex-direction: column;
    &:first-child {
      margin-top: 50px;
      margin-bottom: 30px;}
    &:nth-child(2) {margin-top: 30px;}}
  @media screen and (max-width: 620px) {
    .resume-section:first-child {
      margin-bottom: 105px}}

  .section-content {
    position: relative;
    padding: 0 25px;}
  @media screen and (max-width: 1000px) {
    .section-content {padding: 0px;}}

  #experience .section-content:not(:first-child) {margin-top: 65px;}

  .shoulder {
    background-color: white;
    float: left;
    height: 100%;
    background-color: rgba(0,0,0,.025);
    min-width: 276px;
    position: fixed;
    width: 27%;}
  @media screen and (max-width: 925px) {
    .shoulder {
      height: 100vh;
      position: unset;}
    .shoulder, .resume {
      float: unset;
      width: 100%;}}

  .social-links {
    margin: 30px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    a:not(:first-child) {
      margin-left: 8px;
    }
    img {
      width: 20px;
      height: 20px;}
    i, ion-icon {
      color: black;
      font-size: 20px;}}

  .me {
    height: 160px;
    width: 160px;
    border-radius: 50%;
    margin: auto;
    filter: grayscale(40%);}

  .shoulder-content {
    background: rgba(0,0,0,.001);
    bottom: 0;
    height: 400px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 85%;
    text-align: center;
    span {padding: 0 1px;}
    h1, h2 {margin: 27px 0;}
    h2 div:not(:last-child) {padding-bottom: 8px;}}
  /*****************************************************************************/
  /* Format: Style */
  /*****************************************************************************/
  /*****************************************************************************/
  /* Pictures: Style */
  /*****************************************************************************/
  #tally-logo {
    margin-bottom: 30px;
    height: 68px;}

  #coinbase-logo, #ramp-logo {height: 48px;}
  @media screen and (max-width: 460px) {
    #coinbase-logo, #ramp-logo {height: 42px;}}

  #instabase-logo {height: 53px;}
  @media screen and (max-width: 460px) {
    #instabase-logo {height: 47px;}}

  #hubspot-logo {height: 63px;}
  @media screen and (max-width: 460px) {
    #hubspot-logo {height: 57px;}}

  #dfci-logo {height: 48px;}
  @media screen and (max-width: 460px) {
    #dfci-logo {height: 45px;}}

  #mit {height: 68px;}
  @media screen and (max-width: 460px) {
    #mit {height: 58px;}}

  #google {height: 68px;}
  @media screen and (max-width: 460px) {
    #google {height: 58px;}}

  .section-img {
    display: block;
    margin: 0 auto 15px;}
  /*****************************************************************************/
  /* Pictures: Style */
  /*****************************************************************************/
  /*****************************************************************************/
  /* Flex & Center: Style */
  /*****************************************************************************/
  .flex-line {display: flex;}
  .flex-left {
    display: flex;
    margin-right: auto;
    justify-content: center;

  }
  .flex-right {
    display: flex;
    margin-left: auto;
    justify-content: center;
    h2 {display: inline-block;}}

  @media screen and (max-width: 620px) {
    .flex-line {
      text-align: center;
      display: unset;
      h1 {margin-bottom: 33px;}}
    .flex-left, .flex-right {
      margin-right: unset;
      margin-left: unset;
      h1 {margin-bottom: 23px;}
      h2 {
        margin-top: 0;
        margin-bottom: 40px;}}}
  .flex-wrap {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;}
  .descriptor {
    text-align: center;
    display: none;
    margin-bottom: 5px;}
  @media screen and (max-width: 925px) {
    .descriptor {display: block;}}
  /*****************************************************************************/
  /* Flex & Center: Style */
  /*****************************************************************************/
  /*****************************************************************************/
  /* Tabs: Style */
  /*****************************************************************************/
  .tabs {
    display: inline-block;}
  .tab, .flex-right h2  {
    display: inline-block;
    line-height: 42px;}
  .tab {
    color: #e1e1e1;
    transition: color 0.2s ease-in;
    cursor: pointer;
    margin: 0 2px;
    &:hover {
      color: rgba(0,0,0,.67);}
    &.active {
      color: black;}}
  /*****************************************************************************/
  /* Tabs: Style */
  /*****************************************************************************/
  /*****************************************************************************/
  /* Skills: Style */
  /*****************************************************************************/
  $short-time: 1000ms;
  $eased-out: ease-out;
  $transition: width $short-time $eased-out, height $short-time $eased-out,
  padding $short-time $eased-out, opacity $short-time $eased-out;
  //$transition: all $short-time $eased-out;

  //.skill-list-container {
  //  display: flex;
  //}
  //.grower {
  //  flex-grow: 1;
  //}

  #software-engineer {
    flex-wrap: wrap;
    display: flex;
    text-align: center;
    justify-content: center;
  }

  .skill-list {
    //zoom: 1;
    //text-align: left;
    //justify-content: space-around;
    //transition: all;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    &:before, &:after {
      content:"";
      display:table;}
    &:after {
      clear: both;}
    }


  .logo-icon {
    //padding: 27px;
    //width: 144px;
    //height: 144px;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 17px;
    width: 130px;
    height: 130px;
    opacity: 1;
    overflow: hidden;
    list-style: none;
    position: relative;
    cursor: pointer;
    //display: inline-block;
    transition: zoom 0.2s ease-in, font-size 0.2s ease-in, height 0.2s ease-in, width 0.2s ease-in, padding 0.2s ease-in;
    i {
      transition: font-size 0.2s ease-in;
      //font-size: 90px;
      font-size: 84px;
    }
    img {
      transition: height 0.2s ease-in, width 0.2s ease-in;
      //height: 90px;
      //width: 90px;
      height: 84px;
      width: 84px;
    }
    .skillTransition {
      //transition: $transition;
    }
    &.skillTransition-enter {
      padding: 0;
      width: 0;
      height: 0;
      opacity: 0;
      &.skillTransition-enter-active {
        //padding: 27px;
        //width: 144px;
        //height: 144px;
        padding: 17px;
        width: 130px;
        height: 130px;
        opacity: 1;
        transition: $transition;
      }}
    &.skillTransition-exit {
      //padding: 27px;
      //width: 144px;
      //height: 144px;
      padding: 17px;
      width: 130px;
      height: 130px;
      opacity: 1;
      &.skillTransition-exit-active {
        padding:0;
        width:0;
        height:0;
        opacity: 0;
        transition: $transition;
      }}}
  @media screen and (max-width: 300px) {
    .logo-icon {
      //padding: 17px;
      //width: 120px;
      //height: 120px;
      padding: 12px;
      width: 104px;
      height: 104px;
      i {
        //font-size: 84px;
        font-size: 70px;
      }
      img {
        //height: 84px;
        //width: 84px;
        height: 70px;
        width: 70px;
      }
      &.skillTransition-enter.skillTransition-enter-active {
        //padding: 17px;
        //width: 120px;
        //height: 120px;
        padding: 12px;
        width: 104px;
        height: 104px;}
      &.skillTransition-exit {
        //padding: 17px;
        //width: 120px;
        //height: 120px;
        padding: 12px;
        width: 104px;
        height: 104px;
      }}}

  .logo-icon:hover {
    //padding: 23px;
    padding: 14px;
    i {
      //font-size: 100px;
      font-size: 94px;
    }
    img {
      //height: 100px;
      //width: 100px;
      height: 94px;
      width: 94px;
    }}
  @media screen and (max-width: 300px) {
    .logo-icon:hover {
      //padding: 19px;
      padding: 12px;
      i {
        //font-size: 80px;
        font-size: 76px;
      }
      img {
        //height: 80px;
        //width: 80px;
        height: 76px;
        width: 76px;
      }}}

  .hover-box {
    position: absolute;
    border-radius: 8px;
    width: 100%;
    background-color: rgba(0,0,0,.8);
    height: 100%;
    top: 0;
    left: 0;
    color: white;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    div {line-height: 1.2;}
    h3 {
      -webkit-margin-before: .7em;
      -webkit-margin-after: .7em;}}
  @media screen and (max-width: 925px) {
    .hover-box.hover {display: none;}}
  @media screen and (min-width: 925px) {
    .hover-box.click {display: none;}}
  /*****************************************************************************/
  /* Skills: Style */
  /*****************************************************************************/
  /*****************************************************************************/
  /* Experience: Style */
  /*****************************************************************************/
  .exp-section {
    position: relative;
    width: 100%;}

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background-color: rgba(0,0,0,.5);}

  //.section-container {
  //  position: relative;
  //}
  //
  //.section-transition-height {transition: height 0.6s ease-in-out;}
  //
  //.section-transition-enter {
  //  opacity: 0;
  //  left: 100%;
  //  position: absolute;
  //
  //  &.section-transition-enter-active {
  //    opacity: 1;
  //    left: 0;
  //    transition: opacity 0.6s ease-out, left 0.6s ease-out;}}
  //.section-transition-leave {
  //  opacity: 1;
  //  position: absolute;
  //  left: 0;
  //  &.section-transition-active {
  //    opacity: 0;
  //    left: -100%;
  //    transition: opacity 0.6s ease-out, left 0.6s ease-out;}}


  .logoTransition-height {transition: height 0.6s ease-in-out;}

  .cross-fade-height {transition: height 0.6s ease-in-out;}

  .cross-fade-enter {
    opacity: 0;
    position: absolute;
    left: 100%;
    &.cross-fade-enter-active {
      opacity: 1;
      left: 0;
      transition: opacity 0.6s ease-out, left 0.6s ease-out;}}
  .cross-fade-leave {
    opacity: 1;
    position: absolute;
    left: 0;
    &.cross-fade-leave-active {
      opacity: 0;
      left: -100%;
      transition: opacity 0.6s ease-out, left 0.6s ease-out;}}

  #exp-details {padding: 0 30px;}
  @media screen and (max-width: 500px) {
    #exp-details {padding: 0 40px;}}
  /*****************************************************************************/
  /* Experience: Style */
  /*****************************************************************************/
  /*****************************************************************************/
  /* Details: Style */
  /*****************************************************************************/
  .details-header {
    text-align: center;
    margin: 35px 0;}
  /*****************************************************************************/
  /* Details: Style */
  /*****************************************************************************/
  /*****************************************************************************/
  /* Photos: Style */
  /*****************************************************************************/
  .album {
    margin: 40px 0 50px;
    h2 {
      text-align: center;
      margin-bottom: 24px;}
    &:first-child .gallery div a img {
      max-height: 540px;
      max-width: 270px;}}

  .gallery {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    justify-content: center;
    div a img {
      max-height: 480px;
      max-width: 270px;}}
  @media screen and (max-width: 925px) {
    .gallery-container {
      overflow: scroll;
      width: 100%;}
    #app-store .gallery {
      width: 1370px;}
    #instructions .gallery {
      width: 1644px;}
    #screenshots .gallery {
      width: 3836px;}
    .gallery {
      overflow: visible;
      flex-wrap: nowrap;}}
  @media screen and (max-width: 620px) {
    .gallery-container {
      margin: 0 20px;
      width: calc(100% - 40px);}}
  ///////here

  .footer_1jyse7p {
    font-size: 13px;
    text-align: center;}
  @media screen and (max-width: 620px) {
    .footer_1jyse7p {font-size: 11px;}}
  /*****************************************************************************/
  /* Photos: Style */
  /*****************************************************************************/
  /*****************************************************************************/
  /* Links: Style */
  /*****************************************************************************/
  #links > h2 {
    margin: 37px 0 25px;
    padding: 0 10px;
    text-align: center;}

  .link-image-padding {padding-top: 40px;}

  #dfci-section-content {
    h1 {margin-bottom: 15px;}
    tr {cursor: pointer;}
    .link-table {
      padding-left: 12.5%;
      max-width: 630px;}
    .logo-icon {overflow: unset;}
    .link-image{width: 170px;}
    td.logo-icon {width: 150px;}}

  #tally-section-content {
    .logo-icon {overflow: unset;}
    .link-table {
      max-width: 435px;}}

  .link-table {margin: 0 auto;}

  .link-text {vertical-align: middle;}

  .multi-logo-icons {
    img {
      position: absolute;
      //background: white;
      border-radius: 45px;}
    img:first-child {
      z-index: 2;
      left: -20px;}
    img:nth-child(2) {
      z-index: 3;
      left: 10px;}
    img:last-child {
      z-index: 4;
      left: 40px;}}

  .link-image {
    display: block;
    width: 140px;
    text-align: center;
    position: relative;
    .logo-icon {
      position: relative;
      padding: 10px;
      width: 110px;
      height: 110px;}}
  @media screen and (max-width: 700px) {
    #dfci-section-content {
      .link-table {padding-left: 10%;}}}
  @media screen and (max-width: 620px) {
    #links h1 {font-size: 26px;}
    #tally-section-content .link-table {max-width: 380px;}
    .link-image {width: 120px;
      .logo-icon {padding: 15px;
        i {font-size: 80px;}}}
    #dfci-section-content {
      .link-table {
        padding-right: 5%;
        max-width: 550px;}
      .link-image {width: 150px;}
      td.logo-icon {width: 130px;}}}
  @media screen and (max-width: 500px) {
    #dfci-section-content {
      .link-image-padding {padding-top: 52px;}
      .link-table {padding-left: 7%;}
      .link-image {width: 170px;}
      td.logo-icon {width: 150px;}}
    .multi-logo-icons {
      img:first-child {left: 0px;}
      img:nth-child(2) {left: 25px;}
      img:last-child {left: 50px;}}}
  @media screen and (max-width: 390px) {
    #tally-section-content .link-table {max-width: 320px;}
    .link-text {padding-right: 0px;}
    .link-image-padding {padding-top: 70px;}
    .link-image {
      width: 85px;
      .logo-icon {
        width: 80px;
        height: 80px;
        padding: 10px;
        i {font-size: 60px;}}}
    #dfci-section-content {
      .link-table {padding-left: 5%;}
      .link-image {width: 120px;}
      td.logo-icon {width: 100px;}}
    .multi-logo-icons {
      img:first-child {left: -5px;}
      img:nth-child(2) {left: 15px;}
      img:last-child {left: 35px;}}}
  @media screen and (max-width: 340px) {
    #tally-section-content .link-table {max-width: 285px;}
    #links .link-text h1 {font-size: 22px;}
    .link-image {width: 78px;}}
  /*****************************************************************************/
  /* Links: Style */
  /*****************************************************************************/
  /*****************************************************************************/
  /* Education: Style */
  /*****************************************************************************/
  #education .flex-line.bold-500 {
    max-width: 700px;
    margin: 0 auto;
    h2 { padding: 0 10px;
      &:first-child {padding-left: 0;}
      &:last-child {padding-right: 0;}}}
  @media screen and (max-width: 620px) {
    #education .flex-line.bold-500 h2 {padding: 0px;}}
  /*****************************************************************************/
  /* Education: Style */
  /*****************************************************************************/
  /*****************************************************************************/
  /* Hobbies: Style */
  /*****************************************************************************/
  .hobby {
    background-color: #30b256;
    border-radius: 5px;
    color: #ffffff;
    display: inline-block;
    list-style: none;
    margin: 15px 15px 0 0;
    padding: 10px 15px;
    text-align: center;
    transition: background-color 0.2s linear;
    &:hover {background-color: #000;}}
  /*****************************************************************************/
  /* Hobbies: Style */
  /*****************************************************************************/
  /*****************************************************************************/
  /* Projects: Style */
  /*****************************************************************************/
  #projects {}
  @media screen and (max-width: 620px) {
    #projects {padding: 0 20px;}}

  .project-link {
    position: absolute;
    right: 0;
    bottom: 16px;
    i {
      transition: color 0.2s ease-in;
      font-size: 23px;
      color: black;
      padding: 10px;}
    &:hover i {color: #F44336;}}
  @media screen and (max-width: 500px) {
    .project-link i {
      font-size: 19px;
      padding: 10px;}}
  @media screen and (max-width: 400px) {
    .project-link i {
      font-size: 23px;
      padding: 10px;}}

  .project-container {
    display: inline-block;
    overflow: hidden;
    width: 32%;
    margin: 0.666%;
    .project {
      position: relative;
      overflow: hidden;
      cursor: pointer;}
    img {
      position: relative;
      top: 0;
      vertical-align: unset;
      max-width: 100%;
      transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);}
    .project-label {
      position: absolute;
      width: 100%;
      height: 55px;
      bottom: -55px;
      background: white;
      padding: 2px 20px 5px;
      transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);}
    p {
      font-size: 13.5px;
      font-weight: 400;
      margin: 8px 0;
      line-height: unset;
      color: black;}
    &.active {
      img {top: -40px;}
      .project-label {bottom: 0px;}}}
  @media screen and (max-width: 620px) {
    .project-container {
      width: 48%;
      margin: 1%;
      img {top: -40px;}
      .project-label {bottom: 0px;}}}
  @media screen and (max-width: 400px) {
    .project-container {
      width: 92%;
      margin: 4%;
      &:first-child { margin-top: 0;}}}
  /*****************************************************************************/
  /* Projects: Style */
  /*****************************************************************************/
}

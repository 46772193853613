@import url(https://fonts.googleapis.com/css?family=Oswald);
@import url(https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700);

$baseColor: #000;
$logoWidth: 100px;
$logoHeight: 80px;
$darkGrayColor: #333;
$mediumGrayColor: #444;
$lightGrayColor: #aaa;
$lightGreenColor: #9ae200;
$mediumGreenColor: #4f9d00;
$mediumOrangeColor: #ff6c00;
$lightWhiteColor: #f7f6f8;
$darkYellowColor: #ffd20d;

#draftkings-app {
  * {
    box-sizing: border-box;

    &:before, &:after {
      box-sizing: border-box;
    }
  }

  color: $lightGrayColor;
  font-size: 12px;
  font-family: "Open Sans", sans-serif;

  img {
    vertical-align: middle;
  }

  a {
    outline: none;
    text-decoration: none;
  }

  ol, ul {
    margin-top: 0;
    margin-bottom: 10px;
  }

  .font {
    font: 12px Open Sans, sans-serif;
    font-style: normal;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    font-variant-numeric: normal;
    font-weight: normal;
    font-stretch: normal;
    font-size: 12px;
    line-height: normal;
    font-family: "Open Sans", sans-serif;
  }

  header {
    top: 0;
    max-height: 102px;
    position: fixed;
    display: block;
    z-index: 999;
    width: 100%;
  }

  .page-content {
    margin-top: 104px;
  }

  li:hover {
    color: $lightGreenColor;
  }

  /*****************************************************************************/
  /* Nav: Style */
  /*****************************************************************************/
  .header-inner {
    z-index: 100;
    border-bottom: none;
    height: 53px;
    position: relative;
    background-color: #383739;
    width: 100%;
    padding: 0px;
    margin: 0 0 45px 0;
  }

  .nav-container {
    position: relative;
    z-index: 1;
  }

  .nav-inner {
    background-color: #383739;

    &:before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      height: 0;
      width: 0;
      opacity: 0;
      z-index: 16777270;
      background-color: #000;
      transition: opacity .3s, width 0s .3s, height 0s .3s;
    }
  }

  .nav-content {
    background-color: #474648;
    width: 100%;
    padding: 0;
    height: 60px;
    z-index: 100;
  }

  .nav {
    max-width: 1024px;
    width: 100%;
    margin: 0 auto;
    height: 60px;
    text-align: left;
    background-image: linear-gradient(90deg, #474648, #383739);
  }

  .nav-left {
    display: inline-block;
    width: 64%;
    max-width: 740px;
    vertical-align: top;
  }

  .nav-list {
    list-style-type: none;
    height: 60px;
    padding: 0 0 0 25px;
    margin: 0;
    position: relative;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;

    li {
      position: relative;
    }

    a {
      -webkit-font-smoothing: antialiased;
      color: #fff;
      padding: 18px 0;
      display: inline-block;
      vertical-align: top;
      font-size: 16px;
      font-weight: 600;
      outline: 0 none;
      text-decoration: none;
      transition: all .12s linear;
    }
  }

  .nav-right {
    float: right;
    background-color: #474648;
    padding-right: 5px;
  }

  .nav-divider {
    position: relative;
    width: 23px;
    height: 60px;
    float: right;
    overflow: hidden;

    &:after {
      width: 25px;
      height: 65px;
      content: "";
      position: absolute;
      top: 2px;
      right: -12px;
      background-color: #474648;
      transform: rotate(20deg);
    }
  }

  .logo-div {
    float: left;
    visibility: visible;
    margin: 7px 0 0 4px;
    outline: 0 none;
    background: url(https://www.draftkings.com/_raptor/images/dk-logo-new.png) no-repeat top left;
    width: 132px;
    height: 72px;
    background-size: 100%;
    position: relative;
    z-index: 9;
  }

  .login-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    position: relative;
    height: 100%;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;

    li {
      position: relative;
    }
  }

  .notif-icon-container {
    position: relative;
    margin: 0 20px 0 5px;
    padding: 15px 0;
  }

  .notif-icon {
    font-size: 22px;
    position: relative;
    color: #e9e8ea;
    display: inline-block;
    width: 22px;

    i {
      font-size: 22px;
      position: relative;
      color: #e9e8ea;
    }
  }

  .notification-popup-container {
    width: 350px;
    max-height: 450px;
    text-align: left;
    padding: 0;
    margin: 0 0 0 -185px;
    left: 50%;
    top: 59px;
    position: absolute;
    z-index: 1000;
    list-style-type: none;
    background-color: #fff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .5);

    &:before {
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid #fff;
      position: absolute;
      content: "";
      left: 50%;
      margin-left: -3px;
      top: -5px;
    }
  }

  .notification-popup {
    max-height: 450px;
    color: #383739;
    overflow: auto;
  }

  .notification-content {
    height: 145px;
    font-size: 16px;
    text-align: center;
    position: relative;
    color: #2b2a2c;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    user-select: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }

  .account-container {
    overflow: auto;
    padding: 10px 10px 10px 0;
  }

  .account-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-size: 100%;
    float: left;
  }

  .ribbon-img {
    position: absolute;
    display: inline;
    width: 12px;
    top: 20px;
    margin-left: 4px;
    left: 65px;
    transition: all .12s linear;
  }

  .account-info {
    float: left;
    padding-left: 10px;
  }

  .username-container {
    display: block;
    min-width: 91px;
    max-width: 122px;
    font-size: 14px;
    color: #fff;
    -webkit-font-smoothing: antialiased !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .username {
    line-height: 1;
    float: left;
    min-width: 76px;
    max-width: 107px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .arrow-container {
    font-size: 12px;
    color: #c2c2c2;
    padding-left: 3px;
    float: right;
  }

  .arrow-icon {
    font-style: normal;
    font-weight: 400;
    line-height: 1;
  }

  .deposit-button {
    background-color: #79c600;
    background-image: none;
    box-shadow: none;
    font-size: 20px;
    padding: 8px 12px;
    border: none;
    line-height: 20px;
    cursor: pointer;
    transition: all .2s linear;
    text-shadow: none;
    border-radius: 2px;
    -webkit-font-smoothing: antialiased;
    letter-spacing: .2px;
    position: relative;
    color: #fff;
    text-decoration: none;
    animation: .2s, linear;
    font: 700 16px Open Sans Condensed, sans-serif;
    text-transform: uppercase;
    border: none;
  }

  /*****************************************************************************/
  /* Nav: Style */
  /*****************************************************************************/
  /*****************************************************************************/
  /* Dropdown: Style */
  /*****************************************************************************/
  .dropdown-list-promo {
    border-radius: 2px;
    padding: 0;
    margin: 0 0 0 -161px;
    left: 50%;
    position: absolute;
    top: 100%;
    z-index: 1000;
    min-width: 160px;
    width: 320px;
    list-style-type: none;
    background-color: #fff;
    border: 1px solid #e9e8ea;
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    transition: all .12s linear;

    li {
      &:hover {
        background-color: #f7f6f8;
        // color: #fff;
        text-decoration: underline;
      }

      &:after {
        content: "";
        display: block;
        width: 95%;
        border-bottom: 1px solid #f7f6f8;
        margin: 0 auto;
      }
    }

    a {
      padding: 7px 10px;
      display: block;
      color: #535254;
      font-weight: normal;
      font-size: 14px;
      transition: all .12s linear;
    }

    &:before {
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid #fff;
      position: absolute;
      content: "";
      left: 50%;
      margin-left: -3px;
      top: -5px;
    }
  }

  .dropdown-list {
    padding: 5px 0;
    margin: 0 0 0 -80px;
    left: 50%;
    position: absolute;
    top: 60px;
    z-index: 1000;
    min-width: 160px;
    width: 160px;
    list-style-type: none;
    background-color: #fff;
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);

    &:before {
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid #fff;
      position: absolute;
      content: "";
      left: 50%;
      margin-left: -3px;
      top: -5px;
    }

    a {
      padding: 4px 10px;
      display: block;
      color: #535254;
      font-size: 12px;
      font-weight: 400;
      transition: all .12s linear;

      &:hover {
        background-color: #79c600;
        color: #fff;
        text-decoration: none;
      }
    }
  }

  /*****************************************************************************/
  /* Dropdown: Style */
  /*****************************************************************************/
  /*****************************************************************************/
  /* Random: Style */
  /*****************************************************************************/
  .mid-banners {
    padding: 1em;
    padding-bottom: 0;
    border-bottom: 3px solid #444;
    background: #222;
    height: 280px;

    .banners {
      display: flex;
      padding: 0;
      margin-bottom: 0;
      list-style: none;
      text-align: center;
      font-size: 0;
      width: 1000px;

      > li {
        font-size: 1.5rem;
        display: inline-block;
        width: 33%;
        margin: 0.1em;
        padding: 0;
        overflow: hidden;

        a {
          display: block;
          width: 100%;
          height: 100%;
          background: #111;
        }

        .marquee {
          width: 335px;
          height: 208px;
          position: relative;
        }

        img {
          float: left;
        }

        .over {
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: fade($baseColor, 90%);
          border-bottom: 3px solid $mediumGrayColor;
        }

        .inner {
          position: absolute;
          margin: 0px auto;
          width: 310px;
          padding: 60px 0 0 0;
          text-align: center;

          div {
            padding-bottom: 10px;
            margin-bottom: 10px;
            border-bottom: 1px solid #fff;
            display: inline-block;
          }

          .right {
            display: block;
            text-align: right;
            margin-top: 20px;
          }

          .right:hover {
            color: $mediumGrayColor;
          }

          .tag {
            color: $mediumGrayColor;
          }
        }
      }
    }

    .news {
      text-align: center;
      line-height: 3em;

      a {
        color: #fd0;
        font-weight: bold;
      }
    }
  }

  #content_title {
    text-align: center;
    padding: 10px;
    margin: 10px;

    span {
      margin: 20px 20px 20px 5px;
    }

    .icon {
      color: $lightGreenColor;
      margin-right: 0px;
      margin-left: 15px;
    }

    a:hover {
      color: $lightGreenColor;
    }

    div {
      width: 150px;
      right: -15px;
      display: inline-block;
      position: absolute;
      font-size: 1em;
      font-weight: bold;

      &span {
        color: $darkYellowColor;
        font-size: 1.1em;
        margin: 5px;
      }
    }
  }

  #content_data {
    .title {
      background-color: #1d1c1e;
      color: #ddd;
      padding: 5px;
      font-weight: bold;
      font-size: 0.9em;
      width: 1007px;

      div {
        display: inline-block;
      }
    }

    .grid {
      background-color: $lightWhiteColor;
      height: 200px;
      overflow-y: scroll;
      overflow-x: hidden;
      width: 100%;

      .data {
        border-bottom: 1px solid #aaa;
        background-color: #EEE;

        &:hover {
          background-color: #fff;
        }

        .contestMark {
          vertical-align: bottom;
        }
      }

      .data div {
        display: inline-block;
        padding: 5px;

        span a {
          color: #474648;
        }

        .ticket {
          color: #5ba900;
          padding-left: 3px;
        }

        .ticket_btn {
          display: inline-block;
          color: #fff;
          background-color: #5ba900;
          width: 13px;
          height: 13px;
          text-align: center;
          font-size: 0.8em;
        }

        .ticket_M {
          display: inline-block;
          color: #fff;
          background-color: #767577;
          width: 13px;
          height: 13px;
          text-align: center;
          font-size: 0.8em;
          margin-right: 3px;
        }
      }

      .data .enter_btn a {
        background-color: #535254;
        border-bottom: 3px solid #1d1c1e;
        width: 70px;
        height: 30px;
        display: inline-block;
        border-radius: 2px;
        text-align: center;
        line-height: 1.9em;

        &:hover {
          border-bottom: 3px solid $lightGreenColor;
        }
      }
    }
  }

  #feature_none_1, #feature_none_2, #feature_none_3 {
    color: #767577;
    padding: 0 10px;
    font-weight: bold;
  }

  #feature_hover_right {
    float: right;
    font-size: 1.4em;
  }

  #promotion {
    position: absolute;
    margin-top: -3px;

    ul li {
      display: block;
      width: 100%;

      a:hover {
        background-color: $lightGreenColor;
        color: #f4f3f5;
      }
    }
  }

  /*****************************************************************************/
  /* Random: Style */
  /*****************************************************************************/
  /*****************************************************************************/
  /* SubNav: Style */
  /*****************************************************************************/
  .subnav-container {
    height: 44px;
    margin-top: -39px;
    background-color: #1d1c1e
  }

  .subnav {
    background: #1d1c1e;
    width: 100%;
    position: absolute;
    top: 60px;
    height: 44px;
    transition: all .2s linear;
  }

  .subnav-content {
    max-width: 1024px;
    width: 100%;
    margin: 0 auto;
    text-align: left;
  }

  .subnav-list {
    margin: 0;
    width: 100%;
    padding: 0 10px 0 150px;
    max-width: 1100px;
    display: flex;
    justify-content: space-between;
    list-style: none;
    transition: all .12s linear;

    li {
      float: left;
      display: list-item;
    }

    span {
      text-decoration: none;
      font-size: 16px;
      font-weight: 600;
      padding: 11px 10px 8px;
      display: block;
      text-transform: uppercase;
      outline: 0 none;

      &.active {
        color: #ff6c00;
      }

      &.disabled {
        color: #767577;
        cursor: default;
      }
    }

    a {
      color: inherit;
      font: inherit;
      transition: all .12s linear;
      text-decoration: inherit;
    }
  }

  /*****************************************************************************/
  /* SubNav: Style */
  /*****************************************************************************/
  /*****************************************************************************/
  /* Marquee: Style */
  /*****************************************************************************/
  .marquee-zone {
    width: 100%;
    margin-top: 13px;
    padding: 20px 0 0 0;
  }

  .marquee-content {
    width: 1024px;
    margin: 0 auto;
    text-align: left;
    position: relative;
  }

  .marquee-pictures {
    height: 208px;
  }

  .marquee {
    display: inline-block;
    position: relative;
    width: 335px;
    height: 208px;
    vertical-align: top;
    text-align: center;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.16);

    img {
      transition: all 0.3s linear;
    }
  }

  .rollover {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(29, 28, 30, 0.86);
    // opacity: 0;
    transition: all 0.4s linear;
    z-index: 99;
    border-bottom: 3px solid #9ae200;
  }

  .rollover-inner {
    padding: 60px 0 0 0;
    margin: 0px auto;
    width: 310px;
    transition: all 0s linear;

    &.rollover-inner-hover {
      opacity: 1;
      transition: all 0.4s linear;
    }

    div {
      border-bottom: 1px solid #fff;
      font-size: 14px;
      padding-bottom: 10px;
      margin-bottom: 10px;
      display: inline-block;
      font-weight: 600;
      color: #fff;
    }

    > span {
      width: 260px;
      display: inline-block;
      font-weight: 600;
      color: #fff;
    }

    .cta-link {
      display: block;
      text-align: right;
      font: 700 18px 'Oswald', sans-serif;
      color: #fff;
      margin-top: 15px;
      border-bottom: 1px solid transparent;
      float: right;
      width: auto;
      text-transform: uppercase;
      transition: all 0.24s linear;

      i {
        color: #9ae200;
        margin-left: 2px;
      }
    }
  }

  .marquee-links {
    padding: 15px 0;
    text-align: center;
    font-size: 13px;
    color: #767577;
    background-color: #2b2a2c;
    width: 1024px;
    margin: 0px auto;
  }

  .marquee-text-container {
    height: 38px;
  }

  #marquee-text {
    color: #ffd20d;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    padding: 10px 0;

    a {
      color: inherit;
    }
  }

  a.link-icon {
    color: #e9e8ea;
    padding: 0 10px;
    font-weight: 600;
    transition: all 0.24s linear;

    span, i {
      vertical-align: middle;

      &:first-child {
        color: #9ae200;
        padding-right: 5px;
        font-size: 15px;
      }
    }
  }

  /*****************************************************************************/
  /* Marquee: Style */
  /*****************************************************************************/
  /*****************************************************************************/
  /* Lobby: Style */
  /*****************************************************************************/
  .dk-main {
    width: 1024px;
    margin: 0 auto;
    text-align: left;
    position: relative;
  }

  .lobby {
    width: 100%;
    float: left;
    padding: 0;
    position: relative;
    margin-bottom: 19px;

    &:before {
      position: absolute;
      content: "";
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      transition: opacity 0.8s, z-index 0.8s;
    }
  }

  .lobby-grid {
    // 	height: 834px;
    overflow: hidden;
    outline: 0px;
    position: relative;
    max-width: 1024px;
    width: 100%;
    border-collapse: inherit;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    color: #474648;
    box-sizing: content-box;
    border-spacing: 0;
  }

  .slick-header {
    background: #1d1c1e url(https://www.draftkings.com/_raptor/images/green-gradient.jpg) no-repeat top right;
    width: 100%;
    overflow: hidden;
    box-sizing: content-box;
    border-left: 0px;
    overflow: hidden;
    position: relative;
    border-bottom: 2px solid #9b9a9c;
  }

  .slick-header-columns {
    left: -1000px;
    width: 2024px;
    white-space: nowrap;
    cursor: default;
    box-sizing: content-box;
    position: relative;
    white-space: nowrap;
    cursor: default;
    overflow: hidden;
  }

  .slick-header-column {
    border-left: 0px;
    border-top: 0px;
    border-bottom: 0px;
    float: left;
    position: relative;
    display: inline-block;
    overflow: hidden;
    font-size: 11px;
    color: #c5c4c6;
    font-weight: bold;
    box-sizing: content-box;
    left: 1000px;
    line-height: 30px;
    border-right: 0;
    padding: 0 4px;
    height: auto;

    span {
      cursor: pointer;
    }

    &:first-child {
      padding-left: 8px;
      padding-right: 0;
    }

    &:last-child {
      span a {
        display: block;
        float: right;
        color: #9ae200;

        i {
          padding-right: 5px;
          font-size: 13px;
        }
      }
    }
  }

  .slick-sort-indicator {
    display: inline-block;
    width: 9px;
    height: 5px;
    margin-left: 3px;
  }

  .slick-viewport {
    width: 100%;
    overflow: auto;
    outline: 0px;
    position: relative;
    // height: 802px;
    box-sizing: content-box;
    background-color: #f7f6f8;
  }

  .grid-canvas {
    // height: 21480px;
    width: 1024px;
    position: relative;
    outline: 0;
  }

  .dk-grid .grid-canvas {
    background-color: #f7f6f8;
  }

  .grid-canvas .slick-row {
    transition: all 0.06s linear;
    background: transparent url(https://www.draftkings.com/_raptor/images/gray-pixel.gif) bottom left repeat-x;
    border: 0px;
    width: 100%;
    height: 40px;
    box-sizing: content-box;

    &.active .slick-cell {
      color: #ff6c00 !important;

      &:nth-child(2) a {
        color: #ff6c00;
      }
    }
  }

  .slick-cell {
    padding: 0 4px;
    line-height: 40px;
    border: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background: transparent url(https://www.draftkings.com/_raptor/images/gray-pixel.gif) bottom left repeat-x;
    transition: background-color 200ms ease-in-out;
    position: absolute;
    vertical-align: middle;
    z-index: 1;
    margin: 0;
    font-weight: bold;
    height: 40px;
    box-sizing: content-box;

    &:first-child {
      left: 0;
      right: 967px;
      padding-left: 11px;
      padding-right: 0;

      i {
        font-size: 19px;

        &:nth-child(2) {
          margin-left: 12px;
        }
      }
    }

    &:nth-child(2) {
      left: 57px;
      right: 597px;

      a {
        color: #474648;
      }
    }

    &:nth-child(3) {
      left: 427px;
      right: 506px;
    }

    &:nth-child(4) {
      left: 518px;
      right: 344px;
      font-size: 13px;
      color: #5ea600;
    }

    &:nth-child(5) {
      left: 680px;
      right: 217px;
    }

    &:nth-child(6) {
      left: 807px;
      right: 104px;

      span {
        padding-left: 4px;
        margin-left: 12px;
      }
    }

    &:last-child {
      left: 920px;
      right: 20px;

      a {
        width: 78px;
        height: 35px;
        padding: 5px 0;
        font-weight: 700;
        text-align: center;
        vertical-align: top;
        touch-action: manipulation;
        display: inline-block;
        margin-bottom: 0;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
        position: relative;
        border: 0;
        font: 700 16px 'Open Sans Condensed', sans-serif;
        text-transform: uppercase;
        text-shadow: none;
        border-radius: 2px;
        -webkit-font-smoothing: antialiased;
        letter-spacing: .2px;
        transition: all 0.2s linear;
        white-space: nowrap;
        user-select: none;
        color: #fff;
        cursor: pointer;
        margin-top: 2px;
        text-decoration: none;
        background-color: #4d4c4e;
        background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0, #474648), color-stop(1, #535254));
        border-bottom: 3px solid #1d1c1e;
      }
    }
  }

  .slick-cell:nth-child(4), .slick-cell:nth-child(5) {
    > a {
      // font-size: 13px;
      i {
        font-size: 16px;
      }

      span {
        padding-left: 4px;
      }
    }
  }

  .ion-social-markdown {
    color: #9b9a9c;
  }

  .ion-star {
    color: #ffd20d;
  }

  .dk-grid .icon {
    font-size: 12px;
    line-height: 40px;
    transition: color 200ms ease-in-out;
    text-decoration: none;
  }

  .dk-grid .slick-cell > a:not(.dk-btn) {
    transition: color 200ms ease-in-out;
  }

  .clear {
    clear: both;
  }

  /*****************************************************************************/
  /* Lobby: Style */
  /*****************************************************************************/
}

@import url('https://fonts.googleapis.com/css?family=Roboto');

/*****************************************************************************/
/* General: Style */
/*****************************************************************************/
* { box-sizing: border-box; }

#dashboard {
  text-align: center;
  font-family: 'Roboto', sans-serif !important;
  background: #24303a;
  color: white;

  #wbs1 .hero, .card-header {
    background: #7A3BAA;
  }

  #wbs3 {
    max-width: 50%;
    .hero, .card-header {
      background: #3D90BC;
    }
  }

  #wbs2 {
    max-width: 50%;
    .hero, .card-header {
      background: #249D6D;
    }
  }

  #wbs2-column {
    .stats-card-content:first-child {
      height: 43%;
      .stats-number {
        margin-top: -5px;
      }
    }
    .stats-card-content:last-child {
      height: 57%;
      .stats-column {
        padding-top: 7px;
        padding-bottom: 30px;
        li {
          line-height: 35px;
          font-size: 33px;
        }
      }
    }
  }

  .container {
    display: flex;
  }

  .page {
    height: 100vh;
    overflow-x: hidden;
  }

  .page-content {
    flex-basis: 0;
    flex-grow: 1;
  }

  .row {
    display: flex;
  }

  .rows-2 {
    height: calc(100% - 114px);
    > .row {
      height: 50%;
    }
  }

  .cards-2 > div.card {
    width: 50%;
  }

  #wbs1 .row:first-child {
    .card:nth-child(odd) {
      width: 25%;
    }
    .card:nth-child(even) {
      flex-grow: 2;
      width: 50%;
    }
  }
  /*****************************************************************************/
  /* General: Style */
  /*****************************************************************************/
  /*****************************************************************************/
  /* c3: Style */
  /*****************************************************************************/
  .c3 text {
    fill: white;
    font-weight: 300;
  }

  .c3 path, .c3 line {
    fill: none;
    stroke: #acacac;
  }

  #freq .c3-axis-x g.tick line {
    display: none;
  }

  .c3-axis-y g.tick line {
    fill: #e5e5e5;
  }

  .c3-legend-background {
    opacity: 1;
    fill: #2e3e4b;
    stroke: #fff;
  }

  #freq .c3-axis-y-label {
    font-size: 17px;
    transform: translateY(-3px);
  }

  .spline-chart .c3-axis-y-label {
    font-size: 17px;
    transform: translateX(-14px) rotate(-90deg) !important;
  }

  #dui-nec-bland.spline-chart .c3-axis-y-label {
    transform: translateX(-12px) rotate(-90deg) !important;
  }

  #enim-bibendum .c3-axis-y-label {
    font-size: 17px;
    transform: translateX(-10px) rotate(-90deg) !important;
  }

  .c3-legend-item {
    font-size: 14px;
  }

  .c3-grid line {
    stroke: #2e3e4b !important;
  }

  .c3-xgrid, .c3-ygrid {
    stroke-dasharray: 0 !important;
  }
  /*****************************************************************************/
  /* c3: Style */
  /*****************************************************************************/
  /*****************************************************************************/
  /* Charts: Style */
  /*****************************************************************************/
  #enim-bibendum {
    .c3-axis-x {
      font-size: 17px;
    }
    .c3-axis-y {
      font-size: 17px;
    }
  }

  #freq {
    .c3-axis-x {
      font-size: 16px;
    }
    .c3-axis-y {
      font-size: 16px;
    }
  }

  .spline-chart {
    .c3-line {
      stroke-width: 3px;
    }
    .c3-axis-x {
      font-size: 16px;
    }
    .c3-axis-y {
      font-size: 15px;
    }
  }

  #dui-nec-bland, #habitasse {
    .c3-line {
      stroke: white !important;
    }
  }
  /*****************************************************************************/
  /* Charts: Style */
  /*****************************************************************************/
  /*****************************************************************************/
  /* Cards: Style */
  /*****************************************************************************/
  .card {
    margin: 15px;
    flex-basis: 0;
    flex-grow: 1;
    position: relative;
    background-color: #1e2730;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .16), 0 4px 20px 0 rgba(0, 0, 0, .12);
    border-radius: 2px;
  }

  .card-header {
    height: 54px;
    font-size: 1.6em;
    line-height: 54px;
    color: white;
  }

  .card-content {
    padding: 12px;
  }

  .cloud-card.card {
    padding: 20px 15px;
  }

  .header-card.card {
    flex-direction: column;
    padding: 0;
    padding-top: 0px;
  }

  .header-card {
    .card-content {
      padding-right: 8px;
      padding-left: 8px;
      height: calc(100% - 54px);
      display: flex;
    }
  }

  .cloud-card-content {
    width: 100%;
    svg {
      margin: auto;
    }
    text {
      font-family: 'Roboto' !important;
      &:nth-child(0) {
        fill: #4000C0 !important;
      }
      &:nth-child(9n+1) {
        fill: #39b3e4 !important;
      }
      &:nth-child(9n+2) {
        fill: #fb7c11 !important;
      }
      &:nth-child(9n+3) {
        fill: #31da7d !important;
      }
      &:nth-child(9n+4) {
        fill: #ae60ca !important;
      }
      &:nth-child(9n+5) {
        fill: #d23b2b !important;
      }
      &:nth-child(9n+6) {
        fill: #f9d70b !important;
      }
      &:nth-child(9n+7) {
        fill: #ea4c87 !important;
      }
      &:nth-child(9n+8) {
        fill: #171ad3 !important;
      }
      &:nth-child(9n+9) {
        fill: #ffffff !important;
      }
    }
  }

  .stats-card.card {
    color: white;
    font-weight: bold;
  }

  .stats-card-content {
    width: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
  }

  .stats-column {
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
    width: 100%;
    padding: 25px 15px;
    li {
      text-transform: capitalize;
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 42px;
      font-size: 36px;
      text-align: center;
    }
  }

  .stats-number {
    margin-top: -15px;
    line-height: 84px;
    font-size: 72px;
  }

  .stats-text {
    line-height: 48px;
    font-size: 42px;
  }

  .stats-label {
    position: absolute;
    top: 70%;
    font-weight: 300;
    width: 100%;
    margin-top: 20px;
    font-size: 19px;
  }

  .ion-checkmark-round {
    color: #2CA02C;
  }
  .ion-close-round {
    color: #EF6670;
  }

  table {
    width: 100%;
    border: none;
    border-collapse: collapse;
    font-size: 18px;
    td, th {
      font-size: 19px;
      height: 40px;
      vertical-align: middle;
    }
    i {
      font-size: 22px;
    }
    img {
      width: 24px;
    }
    thead tr {
      font-size: 19px;
      font-weight: 600;
      border-top: 2px solid white;
      border-bottom: 2px solid white;
    }
    td span, td div {
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
      text-align: -webkit-center;
      height: 20px;
    }
    tbody tr {
      &:nth-child(odd) {
        background: #2e3e4b
      }
      &:first-child th {
        border-top: 0;
      }
      &:last-child td {
        border-bottom: 0;
      }
    }
  }

  .table-card.header-card {
    .sk-folding-cube {
      top: 50%;
      transform: rotateZ(45deg) translateY(-50%);
    }
    .card-content {
      overflow: hidden;
      padding: 0;
      display: block;
    }
  }
  /*****************************************************************************/
  /* Cards: Style */
  /*****************************************************************************/
  /*****************************************************************************/
  /* Nav: Style */
  /*****************************************************************************/
  .nav-container {
    height: 114px;
  }

  .nav {
    margin-left: 2rem;
    margin-right: 2rem;
    font-size: 52px;
    line-height: 90px;
    height: 90px;
  }
  /*****************************************************************************/
  /* Nav: Style */
  /*****************************************************************************/
  .hero {
    background-color: #09d0c9;
    height: 24px;
    box-sizing: border-box;
  }

  .sk-folding-cube {
    margin: auto;
    width: 50px !important;
    height: 50px !important;
  }
}

@import url(https://fonts.googleapis.com/css?family=Roboto:400);
//#D32F2F
/*****************************************************************************/
/* General: Style */
/*****************************************************************************/
$material-red: #f44336;
$material-blue: #3f51b5;
$grey: #616161;

.grey {color: $grey;}

.text {
  font-size: 15px;
  line-height: 18px;
  font-weight: 400;}

#filter-card {
  height: 235px;
  padding: 25px 0;}

#search-title {
  font-size: 21px;
  color: rgba(0,0,0,.87);}

.jumbotron-container {
  width: 85%;
  margin: 100px auto;
  h2 {
    margin-left: 15px;}}

.jumbotron {
  background: #fff;
  padding: 20px 50px 40px;
  min-height: 2020px;
  margin-bottom: 30px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);}
/*****************************************************************************/
/* General: Style */
/*****************************************************************************/
/*****************************************************************************/
/* Pages: Style */
/*****************************************************************************/
#page-card-container {
  padding: 60px 0;
  background-color: #fafafa;
  
#page-card {
  background: white;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 
              0 3px 1px -2px rgba(0, 0, 0, 0.2),
              0 1px 5px 0 rgba(0, 0, 0, 0.12);
  margin: 0 50px 50px;}

#pages-container {
  position: relative;}

#pages {
  display: flex;
  padding-bottom: 25px;
  position: relative;}

#new-page {
  font-family: "Roboto", sans-serif;
  background-color: #fafafa;
  padding-bottom: 10px;
  h2 {
    margin: 20px 0 25px;
    font-size: 20px;
    line-height: 24px;}
  h3 {
    margin: 0px 0 20px 15px;
    font-size: 18px;
    line-height: 21px;}
  a, a:-webkit-any-link {
    color: unset;
    text-decoration: unset;
    cursor: auto;}}

.page-container {
  width: 50%;
  display: inline-block;
  zoom: 0.5;}
/*****************************************************************************/
/* Pages: Style */
/*****************************************************************************/
/*****************************************************************************/
/* Intro: Style */
/*****************************************************************************/
#intro {
  padding: 60px 0 0;
  font-family: "Roboto", sans-serif;}
  
.intro-card-container {
  width: 740px;
  margin: 0 auto;}
    
.intro-card {
  margin: 50px 0;
  p {
    margin-top: 29px;
    --x-height-multiplier: 0.35;
    --baseline-multiplier: 0.179;
    letter-spacing: .01rem;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 1.58;
    letter-spacing: -.003em;}
  a, a:-webkit-any-link {
    color: rgb(244, 67, 54);
    text-decoration: unset;
    cursor: auto;}}

.intro-card-content {
  margin: 40px 0 30px;}

.intro-header {
  font-weight: 600;
  cursor: default;
  outline: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;}
  
h2.intro-header {margin: 19px 0;}
h3.intro-header {margin: 13px 0;}
  
.intro-design-section {
  padding-bottom: 30px;
  p:nth-child(2) {
    margin-top: 25px;}}
/*****************************************************************************/
/* Intro: Style */
/*****************************************************************************/
/*****************************************************************************/
/* Button: Style */
/*****************************************************************************/
.slide {
  background: $material-red;
  height: 4px;
  position: absolute;
  bottom: 0;
  transition: left 0.3s ease-out;}

.tab {
	font-size: 16px;
	text-transform: uppercase;
  height: 60px;
  line-height: 60px;
	position: relative;
	text-align: center;
	user-select: none;
	font-weight: 600;
	opacity: 0.6;
	display: inline-block;
	transition: all 200ms ease-in-out;
	cursor: pointer;
	overflow: hidden;
	font-family: "Roboto", sans-serif;
  &.active {opacity: 1;}}

.tabs {
  width: 740px;
  margin: 25px auto 40px;
  position: relative;
  display: flex;}
/*****************************************************************************/
/* Button: Style */
/*****************************************************************************/
/*****************************************************************************/
/* Card: Style */
/*****************************************************************************/
.card {
  // box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.24), 0 0 50px 13px rgba(0, 0, 0, 0.19);
  padding: 10px 22px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  .title:first-child {margin-top: 15px;}}

.title {
  display: block;
  width: 100%;
  padding: 0;
  margin: 20px 0 15px;
  font-size: 12px;
  font-weight: 500;
  height: 30px;
  line-height: 30px;
  text-transform: uppercase;
  color: white;
  background-color: $material-red;
  border: 0;
  padding-left: 15px;}
  
.row {
  display: flex;
  flex-wrap: nowrap;}
.column {
  display: flex;
  flex-direction: column;}
.row>div {flex: 1}
.row div.column-one-third {flex: 1;}
.row div.column-two-thirds {flex: 2;}
.row div.column-one-half {flex: 1;}
/*****************************************************************************/
/* Card: Style */
/*****************************************************************************/
/*****************************************************************************/
/* Dropdown: Style */
/*****************************************************************************/
.dropdown-menu {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  overflow: hidden;
  position: absolute;
  background: #fff;
  left: 0;
  right: 0;
  -webkit-transition: max-height 0.28s ease;
  transition: max-height 0.28s ease;
  z-index: 100;
  max-heigt: 200px;
  .dropdown-transition-enter {
    opacity: 0;
    &.dropdown-transition-enter-active {
      opacity: 1;
      transition: opacity .28s ease-in;}}
  .dropdown-transition-leave {
    opacity: 1;
    &.dropdown-transition-leave-active {
      opacity: 0;
      transition: opacity .28s ease-in;}}}
  .dropdown-transition-height {transition: height .28s ease-in-out;}

.dropdown-list, .dropdown-item {
  list-style: none;
  margin: 0;
  padding: 0;}
  
.dropdown-list {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  max-height: 200px;}

.dropdown-item {
  padding: 5px 10px;
  cursor: pointer;
  &:hover {
    background: $material-blue;
    color: #fff;}}
/*****************************************************************************/
/* Dropdown: Style */
/*****************************************************************************/
/*****************************************************************************/
/* Textfield: Style */
/*****************************************************************************/
.textfield {
  position: relative;
  font-size: 16px;
  display: block;
  box-sizing: border-box;
  max-width: 100%;
  margin: 0 15px;
  padding: 18px 0 20px;
  .icon-button {
    padding: 0;
    width: 25px;
    height: 22px;}}

.textfield-textbox {
  min-height: 160px;
  line-height: 1.4;
  padding: 5px 0;
  border-bottom: 1px solid rgba(0,0,0,.12);}
  
.textfield-input {
  border: none;
  border-bottom: 1px solid rgba(0,0,0,.12);
  display: block;
  font-size: 16px;
  margin: 0;
  padding: 4px 0;
  width: 100%;
  background: 0 0;
  text-align: left;
  color: inherit;
  &:focus{outline-offset: -2px;}}
  
.textfield-label {
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  position: absolute;
  display: block;
  color: $material-blue;
  font-size: 12px;
  top: 4px;
  visibility: visible;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
  &:after {
    background-color: $material-blue;
    top: 40px;
    content: '';
    height: 2px;
    left: 45%;
    position: absolute;
    transition-duration: .2s;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    visibility: hidden;}}

.isFocused {
  .textfield-input {outline: none;}  
  .textfield-label:after {
    left: 0;
    visibility: visible;
    width: 100%;}}
/*****************************************************************************/
/* Textfield: Style */
/*****************************************************************************/
/*****************************************************************************/
/* Header: Style */
/*****************************************************************************/
.header {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;
  height: 80px;
  position: relative;
  z-index: 3;
  background-color: $material-blue;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);}
  
.header-spacer {flex-grow: 1;}

.header-nav {
  cursor: pointer;
  height: 80px;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;}
  
.nav-link {
  color: #fff;
  line-height: 80px;
  padding: 0 17px;
  display: inline-block;
  text-decoration: none;
  margin: 0;
  font-size: 16px;
  &:last-child {
    padding-right: 40px;}}
  
.header-title {
  position: relative;
  font-size: 22px;
  padding-left: 40px;
  line-height: 80px;
  letter-spacing: .02em;
  box-sizing: border-box;}
/*****************************************************************************/
/* Header: Style */
/*****************************************************************************/
/*****************************************************************************/
/* Table: Style */
/*****************************************************************************/
.ion.ion-arrow-down-c {
  transition: all .5s ease-in;
  font-size: 14px;
  vertical-align: middle;
  &.up {
    transform: rotate(180deg);
    margin-left: 5px;
    vertical-align: baseline;}}

.group-table {
  cursor: pointer;
  border-spacing: 0;
  width: 100%;
  table-layout: fixed;
  outline: none;
  -webkit-user-select: none;}

.header-row {
  background: $material-blue;
  line-height: 36px;
  height: 36px;}
  
.body-row {
  height: 40px;
  &:hover, &.active { background-color: #eeeeee;
    .bold { font-weight: 600;}}}
  .table-transition-enter {
    opacity: 0;
    &.table-transition-enter-active {
      opacity: 1;
      transition: opacity .5s ease-in;}}
  .table-transition-leave {
    opacity: 1;
    &.table-transition-leave-active {
      opacity: 0;
      transition: opacity .5s ease-in;}}
  .table-transition-height {transition: height .5s ease-in-out;}
  
.header-row, .body-row {
  div[data-col="details"] {width: calc(67% - 90px);}
  div[data-col="status"] {width: 100px;}
  div[data-col="title"] {width: calc(33% - 10px);}}

.header-cell {
  height: 36px;
  display: inline-block;
  text-transform: uppercase;
  text-indent: 4px;
  font-size: 12px;
  font-weight: 600;
  color: white;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
    i.icon {
    width: 16px;
    height: 16px;}}

.row-cell {
  display: inline-block;
  text-indent: 4px;
  height: 40px;
  line-height: 40px;
  max-height: 40px;
  min-height: 40px;
  font-size: 14px;
  color: $grey;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  &[data-col="title"] {
    overflow: visible;
    color: black;}
  a {text-decoration: none;}}

.body-row .row-cell, .header-row div {
  &:first-child {
    padding-left: 16px;
    padding-right: 10px;}
  &:last-child {
    padding-left: 10px;
    padding-right: 16px;}}

.column-content {
  display: block;
  position: relative;
  background-color: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 40px;}

.right-items {
  float: right;
  background-color: inherit;
  line-height: 0;
  text-indent: 0;}

.card {
  .group-table {text-align: center;}
  .header-row {
    background: unset;
    line-height: 15px;
    height: 15px;}
  .header-cell {
    height: 15px;
    color: $material-blue;
    text-transform: unset;
    font-weight: unset;
    width: 25%;}
  .body-row {height: 30px;}
  .row-cell {
    width: 25%;
    height: 32px;
    color: black;
    font-size: 16px;
    line-height: 30px;
    max-height: 32px;
    min-height: 32px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);}
  .card-transition-enter {
    opacity: 0;
    &.card-transition-enter-active {
      opacity: 1;
      transition: opacity .5s ease-in, box-shadow .5s ease-in;}}
  .card-transition-leave {
    opacity: 1;
    &.card-transition-leave-active {
      opacity: 0;
      transition: opacity .5s ease-in, box-shadow .5s ease-in;}}}
  .card-transition-height {transition: height .5s ease-in-out;}
/*****************************************************************************/
/* Table: Style */
/*****************************************************************************/
/*****************************************************************************/
/* Tooltip: Style */
/*****************************************************************************/
.tooltip {
  transform: scale(0) translateX(-50%);
  transform-origin: top center;
  z-index: 999;
  background: rgba(97,97,97,.9);
  border-radius: 2px;
  color: #fff;
  display: inline-block;
  font-weight: 500;
  max-width: 170px;
  position: absolute;
  line-height: 12px;
  font-size: 12px;
  padding: 10px;
  top: 30px;
  left: 50%;
  text-align: center;
  &.is-active {animation: pulse 200ms cubic-bezier(0,0,.2,1)forwards;}}

@keyframes pulse {
  0% {
    transform: scale(0) translateX(-50%);
    opacity: 0;}
  50% {transform: scale(.99) translateX(-50%);}
  100% {
    transform: scale(1) translateX(-50%);
    opacity: 1;
    visibility: visible;}}
/*****************************************************************************/
/* Tooltip: Style */
/*****************************************************************************/
/*****************************************************************************/
/* IconButton: Style */
/*****************************************************************************/
.icon-button {
  display: inline-block;
  position: relative;
  padding: 8px;
  outline: none;
  user-select: none;
  cursor: pointer;
  z-index: 0;
  line-height: 1;
  text-align: center;
  width: 35px;
  height: 40px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  a {color: unset};
  i.icon {
    font-size: 18px;
    line-height: 24px;
    text-align: center;}
  &:hover {color: $material-red;}
  &[disabled] {
    color: #e0e0e0;
    pointer-events: none;
    cursor: not-allowed;
    &:hover {color: #e0e0e0;}}}
/*****************************************************************************/
/* IconButton: Style */
/*****************************************************************************/
}